import { useEffect, useState } from "react";
import useZustant from "../hooks/useZustant";
import { ThemeProvider } from "@emotion/react";
import theme from "../theme";
import {
  Container,
  Toolbar,
  Typography,
  TextField,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Link,
} from "@mui/material";
import { Box } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../App.css";
import LaunchIcon from "@mui/icons-material/Launch";
import { useNavigate } from "react-router-dom";

const headCells = [
  {
    id: "order",
    numeric: false,
    disablePadding: false,
    label: "Order #",
  },
  {
    id: "placed",
    numeric: false,
    disablePadding: false,
    label: "Placed",
  },
  {
    id: "placedBy",
    numeric: false,
    disablePadding: false,
    label: "Placed By",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "service",
    numeric: false,
    disablePadding: false,
    label: "Service",
  },
  {
    id: "size",
    numeric: false,
    disablePadding: false,
    label: "Size",
  },
  {
    id: "cost",
    numeric: false,
    disablePadding: false,
    label: "Cost",
  },
];

const staticData = [
  {
    order: "TC0396915073",
    placed: "2021-08-10",
    placedBy: "John Doe",
    status: "Completed",
    service: "Transcript",
    size: "2h",
    cost: "$100.00",
  },
  {
    order: "TC0255336679",
    placed: "2022-04-14",
    placedBy: "Silvie Conor",
    status: "Completed",
    service: "Transcript",
    size: "3m",
    cost: "$3.00",
  },
  {
    order: "TC0335733365",
    placed: "2021-08-10",
    placedBy: "John Doe",
    status: "Cancelled",
    service: "Auto Transcript",
    size: "1m",
    cost: "$0.27",
  },
];

export default function OrderHistory() {
  const { setCurrentClientPage, changeIsInClientsSettings } = useZustant();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Order History - Focus Forward";
    setCurrentClientPage("Order History");
    changeIsInClientsSettings(false);
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - staticData.length) : 0;

  function stableSort(array) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container
          style={{
            width: "100%",
            paddingTop: 4,
          }}
        >
          <Typography variant="h1" sx={{ mb: 5, mt: 5 }}>
            Order History
          </Typography>

          <Typography variant="h3" sx={{ mb: 3, fontWeight: 400 }}>
            Account #946182454
          </Typography>

          <Box sx={{ width: "100%" }}>
            <Toolbar>
              <Typography variant="h5" className="toolbarLabel">
                FROM
              </Typography>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                renderInput={(params) => <TextField {...params} />}
                className="datePicker"
              />
              <Typography variant="h5" className="toolbarLabel">
                TO
              </Typography>

              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                renderInput={(params) => <TextField {...params} />}
                sx={{ padding: 0 }}
                className="datePicker"
              />
              <Button className="toolbarButton">Reset</Button>
              <Button
                color="fourth"
                sx={{ fontWeight: "bold" }}
                endIcon={<LaunchIcon />}
                className="toolbarButton"
              >
                Export
              </Button>
            </Toolbar>

            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table aria-label="simple table" size="large">
                  <TableHead sx={{ backgroundColor: "#f3f3f3" }}>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={"left"}
                          sx={{
                            minWidth: "75px !important",
                          }}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {stableSort(staticData).map((data) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Link
                            sx={{
                              textDecoration: "none",
                              color: theme.palette.primary.main,
                              cursor: "pointer",
                            }}
                          >
                            {data.order}
                          </Link>
                        </TableCell>
                        <TableCell>{data.placed}</TableCell>
                        <TableCell>{data.placedBy}</TableCell>
                        <TableCell>{data.status}</TableCell>
                        <TableCell>{data.service}</TableCell>
                        <TableCell>{data.size}</TableCell>
                        <TableCell>{data.cost}</TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 1 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={staticData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Container>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
