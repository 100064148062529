import { Navigate, Outlet } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import useZustant from "../hooks/useZustant";

export default function TranscriberPrivateRoute({
  redirectTo = "/",
  children,
}) {
  const { user } = useZustant();

  if (!user && user !== undefined) {
    return <Navigate to="/" replace />;
  }

  if (user.rolename === "Admin" || user.rolename === "PM") {
    return <Navigate to="/dashboard" replace />;
  } else if (user.rolename === "Transcriber") {
    return children ? children : <Outlet />;
  } else if (user.rolename === "QA") {
    return <Navigate to={`/projects/qa`} replace />;
  } else if (user.rolename === "Client") {
    return <Navigate to={`/filemanagement`} replace />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress color="fourth" />
        </Box>
      </ThemeProvider>
    );
  }
}
