import { useState, useEffect } from "react";

import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Rating,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/Folder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen } from "@fortawesome/free-solid-svg-icons";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Utils from "../../utils";
import "../../App.css";
import Config from "../../config";
import MoveFilesModal from "./moveFilesModal";
import useShow from "../../hooks/useShow";
import awsUtils from "../../awsUtils";
import { useDrag, useDrop } from "react-dnd";
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';

const options = ["Download", "Move", "Rename", "Delete"];

export default function FileManagerRow(props) {
  const {
    row,
    isItemSelected,
    labelId,
    filesAction,
    changePrefix,
    folderPrefix,
    handleClick,
    itemHeight,
    updateFiles,
    folder,
    handleAlert,
    selected,
    clearSelected,
  } = props;

  const { parseSize } = Utils;
  const [anchorEl, setAnchorEl] = useState(null);

  const ItemTypes = {
    item: "file/folder",
  };

  const move = (row, target) => {
    setLoading(true);
    if (row.id === target) {
      handleAlert("error", "You can't move a folder to itself!");
      return;
    } else {
      const prefixes = !row.isDir
        ? row.id.replace(row.name, "")
        : row.id.replace(row.name + "/", "");
      if (prefixes === target) {
        handleAlert(
          "warning",
          "You cannot move the file/folder into the same folder where that file/folder is located."
        );
      } else {
        moveItem(row, target)
          .then((res) => (row.isDir ? moveFolder(row, res) : null))
          .then(() => {
            updateFiles();
            clearSelected();
            setLoading(false);
          });
      }
    }
  };

  const [, drag, preview] = useDrag(() => ({
    type: ItemTypes.item,
    item: { row },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        move(item.row, dropResult.row.id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.item,

    drop: () => ({ row }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const {
    emptyS3Directory,
    deleteItem,
    renameFolder,
    renameFile,
    moveItem,
    moveFolder,
  } = awsUtils;

  const { show: showModal, close: closeModal, open: openModal } = useShow();

  const [isRenaming, setIsRenaming] = useState(false);

  const [rating, setRating] = useState(0);

  const open = Boolean(anchorEl);

  const openMenu = (event) => setAnchorEl(event.currentTarget);

  const closeMenu = () => setAnchorEl(null);

  const [isLoadingName, setIsLoadingName] = useState(false);

  const convert = (date) => {
    if (date) {
      return date.toLocaleDateString();
    } else {
      return null;
    }
  };

  const [loading, setLoading] = useState(false);

  const RenderperType = (element) =>
    element.isDir ? (
      <FolderIcon className="filesIcon" />
    ) : (
      <FontAwesomeIcon icon={faFilePen} className="filesIcon" style={{fontSize: '20px', color: "#1A336E"}} />
    );

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoadingName(true);
    try {
      const data = e.currentTarget;

      const formdata = new FormData(data);

      const name = formdata.get("name");

      const getPureName = row.name.split(".")[0];

      const newId = row.id.replace(getPureName, name);

      if (name === getPureName) {
        handleAlert("warning", "The name is the same");
        return;
      }

      if (!row.isDir) {
        renameFile(row.id, newId).then((res) => {
          updateFiles();
          setIsRenaming(false);
          clearSelected();
        });
      } else {
        renameFolder(row.id, newId).then(async (res) => {
          updateFiles();
          setIsRenaming(false);
          clearSelected();
        });
      }
    } catch (e) {
      handleAlert("error", "There was an error renaming the item");
      setIsRenaming(false);
    }
  };

  const actions = (option, key) => {
    if (option === "Download") {
      filesAction(option, key);
      closeMenu();
    }

    if (option === "Rename") {
      setIsRenaming(true);
      closeMenu();
    }

    if (option === "Delete") {
      if (row.isDir) {
        emptyS3Directory(Config.awsBucketName, row.id).then(() => {
          updateFiles();
        });
      } else {
        deleteItem(Config.awsBucketName, row.id).then(() => {
          updateFiles();
        });
      }
      closeMenu();
    }

    if (option === "Move") {
      openModal();
    }
  };

  const RenameInput = () => {
    useEffect(() => {
      const input = document.getElementById("renameInput");
      if (input) {
        input.focus();
        input.select();
      }
    }, []);
    return (
      <div style={{ display: "flex" }}>
        <form
          onSubmit={(e) => handleSubmit(e)}
          style={{ pointersEvents: "none", marginRight: "10px" }}
        >
          <input
            style={{ pointersEvents: "none" }}
            defaultValue={row.name.split(".")[0]}
            className="renameInput"
            id="renameInput"
            onBlur={() => setIsRenaming(false)}
            name="name"
            disabled={isLoadingName}
          ></input>
        </form>
        {isLoadingName ? <CircularProgress size={20} /> : null}
      </div>
    );
  };

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#66BB6A',
    },
    '& .MuiRating-iconHover': {
      color: '#66BB6A',
    },
  });


  return (
    <TableRow
      hover
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      id={row.id}
      selected={isItemSelected}
      sx={{ cursor: "pointer" }}
      className="parent"
    >
      <MoveFilesModal
        folder={folder}
        show={showModal}
        close={closeModal}
        folderPrefix={folderPrefix}
        currentFolder={folderPrefix[folderPrefix.length - 1]}
        row={row}
        updateFiles={updateFiles}
        handleAlert={handleAlert}
        clearSelected={clearSelected}
      />
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          onClick={(event) => handleClick(event, row)}
          inputProps={{
            "aria-labelledby": labelId,
          }}
          className="checkBox"
          id={`check${labelId}`}
          sx={isItemSelected ? { display: "block !important" } : {}}
        />
      </TableCell>
      <TableCell
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        onClick={isRenaming ? null : () => changePrefix(folderPrefix, row)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          ref={row.isDir ? (node) => drag(drop(node)) : drag}
          data-testid={`box`}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <>
              {RenderperType(row)}
              {isRenaming ? (
                <RenameInput name={row.name} />
              ) : (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "280px",
                  }}
                >
                  {row.name}
                </span>
              )}
            </>
          )}
        </div>
      </TableCell>
      <TableCell
        align="right"
        onClick={() => {
          changePrefix(folderPrefix, row);
        }}
      >
        {row.size ? parseSize(row.size) : "0 KB"}
      </TableCell>
      <TableCell
        align="right"
        onClick={() => {
          changePrefix(folderPrefix, row);
        }}
      >
        {convert(row.modDate)}
      </TableCell>
      <TableCell>
        {row.isDir ? null : (
          <StyledRating
            value={rating}
            onChange={(event, value) => {
              setRating(value);
            }}
            size="small"
          />
        )}
      </TableCell>
      <TableCell>{row.isDir ? null : <>Pending</>}</TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={openMenu}
          sx={
            selected.length > 0
              ? { color: "transparent !important", pointerEvents: "none" }
              : null
          }
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          PaperProps={{
            style: {
              maxHeight: itemHeight * 4.5,
              width: "20ch",
            },
          }}
        >
          {options.map((option) => {
            if (row.isDir && option === "Download") {
              return null;
            } else {
              return (
                <MenuItem key={option} onClick={() => actions(option, row.id)}>
                  {option}
                </MenuItem>
              );
            }
          })}
        </Menu>
      </TableCell>
    </TableRow>
  );
}
