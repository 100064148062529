import "./App.css";
import NavBarComponent from "./components/Navbar";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { Box, useMediaQuery } from "@mui/material";
import useZustant from "./hooks/useZustant";
import { useLocation } from "react-router-dom";
import ClientSidebar from "./components/ClientSidebar";
import TranscriberTabs from "./components/TranscriberWork/TranscriberTabs";

export default function App() {
  const { user, isOpenSidebar } = useZustant();
  const matches = useMediaQuery("(max-width:790px)");
  const mobile = useMediaQuery("(max-width:400px)");
  const isClient = user.rolename === "Client";
  const location = useLocation();

  const isInPayment = location.pathname === "/uploadFiles"

  return (
    <Box
      sx={{
        height: "100%",
      }}
      className={isClient ? "clientAppContainer" : null}
    >
      <ThemeProvider theme={theme}>
        <div className={isClient ? "clientFlexContainer" : null}>
          {isClient ? <NavBarComponent /> : null}
          {isClient ? <ClientSidebar /> : null}
          <div
            id="mainContainer"
            style={
              isClient
                ? {
                    paddingTop: 150,
                    height: "100%",
                  }
                : null
            }
            className={
              isClient
                ? mobile
                  ? "mobileContainer"
                  : matches
                  ? "mediumContainer"
                  : isOpenSidebar && !isInPayment
                  ? "sideBarTrue"
                  : "sideBarFalse"
                : null
            }
          >
            <TranscriberTabs />
            <Outlet />
          </div>
        </div>
      </ThemeProvider>
    </Box>
  );
}
