import React, { useEffect, useState, useRef } from "react";
import { TableRow, TableCell, Button } from "@mui/material";

import "../App.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

import useZustant from "../hooks/useZustant";
import Utils from "../utils";

import RenderCost from "./fileItem/RenderCost";

function FileItem(props) {
  const {
    file,
    index,
    folder,
    Config,
    s3,
    deleteFile,
    fetchS3Folders,
    handleFilesSetTrue,
    handleAlert,
    estimatedDeliveryTime,
    basicRates
  } = props;

  const progressBar = useRef(null);
  const isTheUploadCancelled = useRef(false);
  const aux = useRef(0);

  const { user , newOrderType } = useZustant();
  const { parseSize } = Utils;

  const [informationUploaded, setInformationUploaded] = useState("0kb");

  const useAux = useRef(0);

  const [complete, setComplete] = useState(false);

  const [durationFile, setDurationFile] = useState("");
  const [cost, setCost] = useState(0.0);

  const handleCost = (value) => setCost(value);

  const isAudioOrVideo = useRef(
    file.type.startsWith("audio/") || file.type.startsWith("video/")
  );

  function getDuration(file) {
    if (file.type.startsWith("audio/") || file.type.startsWith("video/")) {
      const element = new Audio();

      element.src = URL.createObjectURL(file);
      return new Promise((resolve) => {
        element.addEventListener("loadedmetadata", () => {
          resolve(element.duration);
        });
      });
    }
  }

  function formatDuration(duration) {
    if (duration < 60) {
      return { format: `${Math.ceil(duration)}M`, duration: duration };
    } else if (duration < 3600) {
      return {
        format: `${Math.floor(duration / 60)} Min(s)`,
        duration: duration,
      };
    } else {
      const hours = Math.floor(duration / 3600);
      return { format: `{${hours} Hour(s)`, duration: duration };
    }
  }

  function calculateTranscriptionTime(duration) {
    // duration is in seconds.
    // min and max are in minutes
    duration = duration/60;
    const transcriptionTime = estimatedDeliveryTime.filter((range)=> duration >= range.min && (duration < range.max || range.max === undefined));
    console.log(transcriptionTime[0].medianTurnaroundTime);
    return transcriptionTime[0].medianTurnaroundTime
  }

  const uploadFile = (trueName) => {
    const params = {
      Bucket: Config.awsBucketName,
      Key: folder.id + trueName + file.name,
      Body: file,
      ContentType: file.type,
    };

    s3.upload(
      params,

      async (err, data) => {
        if (err) {
          isTheUploadCancelled.current = true;
        } else {
          if (isTheUploadCancelled.current === true) {
            s3.deleteObject({
              Bucket: Config.awsBucketName,
              Key: folder.id + trueName + file.name,
            }).promise();
          } else {
            isTheUploadCancelled.current = false;
            setComplete(true);
            if (isTheUploadCancelled.current === false) {
              let duration = await getDuration(file);
              if (duration === undefined) {
                duration = 0;
              }

              const durationInMinutes = duration / 60;
              const fixedCost = basicRates[newOrderType];
              const currentCost = durationInMinutes * basicRates[newOrderType];
              const formattedCost = currentCost.toFixed(2);

              let paramCost;
              if (!isAudioOrVideo.current) {
                paramCost = 0;
              } else if (duration < 60) {
                paramCost = fixedCost;
              } else {
                paramCost = Number(formattedCost);
              }
              handleFilesSetTrue(
                `${file.name}_${file.size}`,
                folder.id + trueName + file.name,
                file.name,
                paramCost.toFixed(2),
                isAudioOrVideo.current
              );
            }
          }
        }
      }
    ).on("httpUploadProgress", (progress) => {
      if (!isTheUploadCancelled.current) {
        const currentUpload = (progress.loaded / progress.total) * 100;

        if (currentUpload < useAux.current) {
        } else {
          useAux.current = currentUpload;
          progressBar.current.style.width = currentUpload + "%";
          setInformationUploaded(parseSize(progress.loaded));
        }
      }
    });
  };

  const RenderEstimate = () => {
    const fileDuration = formatDuration(durationFile);
    const estimate = calculateTranscriptionTime(fileDuration.duration);

    if (isAudioOrVideo.current === true) {
      return <span>{estimate}</span>;
    } else {
      return <span>Not an audio/video file</span>;
    }
  };

  function RenderDuration() {
    const format = formatDuration(durationFile);
    const value = format.format;

    if (durationFile === "") {
      return null;
    } else {
      if (isAudioOrVideo.current === true) {
        return <span style={{ fontWeight: 300 }}> {value} </span>;
      } else {
        return null;
      }
    }
  }

  useEffect(() => {
    fetchS3Folders(folder.id).then((folders) => {
      const folderName = user.id + "_Temp";

      const isTempFolder = folders.find((folder) => folder.name === folderName);

      const trueName = folderName + "/";

      if (!isTempFolder) {
        const params = {
          Key: folder.id + trueName,
          Body: "",
          Bucket: Config.awsBucketName,
          ACL: "private",
        };

        s3.upload(params, (err, data) => {
          if (err) {
            handleAlert("error", "Error uploading the file");
          }
        })
          .promise()
          .then((res) => {
            uploadFile(trueName);

            if (isAudioOrVideo.current === true) {
              getDuration(file).then((duration) => {
                setDurationFile(duration);
              });
            }
          });
      } else {
        uploadFile(trueName);

        if (isAudioOrVideo.current === true) {
          getDuration(file).then((duration) => {
            setDurationFile(duration);
          });
        }
      }
    });

    return () => {
      aux.current = aux.current + 1;
    };
  }, [file]);

  return (
    <TableRow sx={{ width: "100%" }}>
      <TableCell sx={{ maxWidth: "100px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="fileName">{file.name}</div>
          <div>
            <Button
              startIcon={
                <FontAwesomeIcon
                  icon={faTrashCan}
                  style={{ fontSize: "14px" }}
                />
              }
              sx={{ fontSize: "10px", maxWidth: "90px" }}
              color="red"
              onClick={() => deleteFile(index)}
            >
              Delete
            </Button>
            <RenderDuration />
          </div>
        </div>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {complete ? (
          <RenderEstimate />
        ) : (
          <div className="progress">
            <div className="progress__fill" ref={progressBar}></div>
            <span className="progress__text">
              {informationUploaded} / {parseSize(file.size)}
            </span>
          </div>
        )}
      </TableCell>
      <TableCell
        sx={{
          textAlign: "center",
        }}
      >
        <RenderCost
          durationFile={durationFile}
          handleCost={handleCost}
          isAudioOrVideo={isAudioOrVideo.current}
          basicRate={basicRates[newOrderType]}
        />
      </TableCell>
    </TableRow>
  );
}

export default React.memo(FileItem);
