import Config from "./config";

function numberToMinutes(num) {
  var hours = Math.floor(num / 60);
  var minutes = num % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return formattedHours + ":" + formattedMinutes;
}
function numberToSeconds(num) {
  // num should be seconds
  const hours = Math.floor(num / 3600);
  const minutes = Math.floor((num % 3600) / 60);
  const remainingSeconds = num % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
function toHHMMSS(time) {
  let secs = Math.round(time),
    hours = Math.floor(secs / 3600),
    minutes = Math.floor((secs - hours * 3600) / 60),
    seconds = secs - hours * 3600 - minutes * 60;

  if (hours > 0) {
    if (hours < 10) {
      hours = "0" + hours;
    }
    hours += ":";
  } else {
    hours = "";
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + minutes + ":" + seconds;
}
function toMMSS(time) {
  let secs = Math.round(time),
    minutes = Math.floor(secs / 60),
    seconds = secs - minutes * 60;

  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + ":" + seconds;
}

const dateConverted = (date) => {
  const targetDate = new Date(date);

  const currentDate = new Date();
  const difference = targetDate - currentDate;

  const hours = Math.floor(difference / (1000 * 60 * 60));

  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

  if (hours > 0 && minutes > 0) {
    return (
      <>
        {hours} hours and {minutes} minutes remaining
      </>
    );
  } else {
    return (
      <>
         {Math.abs(hours)} hours and {Math.abs(minutes)} minutes ago
      </>
    );
  }
};

const decimalDateConverter = (date) => {
  const targetDate = new Date(date);
  const currentDate = new Date();
  const difference = targetDate - currentDate;

  const hours = Math.floor(difference / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

  return (
    <>
      {hours}h {minutes}m 
    </>
  );
}

const transformPayRate = (amount, unitLength) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount * (unitLength / 60));
};

const qualitativeGrade = function (grade) {
  if (!grade) {
    return "N/A";
  } else if (grade <= 100 && grade >= 90) {
    return "A";
  } else if (grade < 90 && grade >= 80) {
    return "B";
  } else if (grade < 80 && grade >= 70) {
    return "C";
  } else if (grade < 70 && grade >= 60) {
    return "D";
  } else if (grade < 60) {
    return "F";
  }
};

function basename(path) {
  return path.split("/").reverse()[0];
}

function baseNameFolder(path) {
  return path.split("/").reverse()[1];
}

function parseSize(sizeInBytes) {
  if (typeof sizeInBytes === "undefined") return;
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} bytes`;
  } else if (sizeInBytes < 1024 ** 2) {
    return `${(sizeInBytes / 1024).toFixed(1)}kb`;
  } else if (sizeInBytes < 1024 ** 3) {
    return `${(sizeInBytes / 1024 ** 2).toFixed(1)}mb`;
  } else {
    return `${(sizeInBytes / 1024 ** 3).toFixed(1)}gb`;
  }
}

const redirectOldUi = (path, replace) => {

  if (replace) {
    window.location.href = `${Config.oldUI}${path}`;
  } else {
    let oldUi = window.open("", "oldUi");

    if (oldUi) {
      oldUi.location.href = `${Config.oldUI}${path}`;
    } else {
      window.open(`${Config.oldUI}${path}`, "oldUi");
    }
  }
};

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validateZipCode = (zip) => {
  zip = zip.toString().replace(/\s+/g, '');
  return (zip.length === 5 || zip.length === 9);
}
const isValidPhoneNumber = (number) => {
  var pattern = /^\d{10}$/;
  return pattern.test(number);
};

const calculateTranscriberPayRate = (
  transcript_transcriber_rate,
  project_transcriber_rate,
  assignment_transcriber_rate,
  transform, user, row
) => {
  let payRate;
  if (assignment_transcriber_rate && assignment_transcriber_rate > 0) {
    payRate = assignment_transcriber_rate;
  } else if (transcript_transcriber_rate && transcript_transcriber_rate > 0) {
    payRate = transcript_transcriber_rate;
  } else if (project_transcriber_rate && project_transcriber_rate > 0) {
    payRate = project_transcriber_rate;
  } else {
    payRate = user.rate.amount;
  }

  if (transform) {
    return transformPayRate(payRate, row.unit_length);
  } else {
    return "$" + payRate.toFixed(2);
  }
};
const Utils = {
  numberToMinutes,
  numberToSeconds,
  toHHMMSS,
  toMMSS,
  dateConverted,
  decimalDateConverter,
  transformPayRate,
  qualitativeGrade,
  basename,
  baseNameFolder,
  parseSize,
  redirectOldUi,
  validateEmail,
  validateZipCode,
  isValidPhoneNumber,
};

export default Utils;
