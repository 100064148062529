import { useEffect } from "react";
import useZustant from "../hooks/useZustant";
import useFetch from "../hooks/useFetch";
import {
  Box,
  Container,
  ThemeProvider,
  Typography,
  Button,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Toolbar,
  Select,
} from "@mui/material";
import theme from "../theme";
import { monthsList } from "../lists";
import "../App.css";
import { useNavigate } from "react-router-dom";
import Requests from "../logic/Requests";

const headCells = [
  { id: "recorder", label: "Recorder On" },
  { id: "placedon", label: "Placed On" },
  { id: "activity", label: "Activity" },
  { id: "usd", label: "USD" },
  { id: "balance", label: "Balance" },
];

export default function TransactionHistory() {
  const { setCurrentClientPage, changeIsInClientsSettings, isOpenSidebar, user, changeClientPrepaidBalance,clientPrepaidBalance  } =
    useZustant();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Transaction History - Focus Forward";
    setCurrentClientPage("Transaction History");
    changeIsInClientsSettings(false);
  }, []);

  const {
    data: clientInformation,
    isLoading,
  } = useFetch(Requests.getClient, user.client);

  useEffect(() => {
    changeClientPrepaidBalance(clientInformation?.prepaidBalance ? clientInformation.prepaidBalance:0);
  }, [isLoading]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }}>
        <Container sx={{ width: "100%", pt: 3 }}>
          <div style={{ marginBottom: "30px" }}>
            <Typography variant="h1">Transaction History</Typography>

            <span style={{ fontWeight: 300 }}>Account #892490510</span>
          </div>

          <div>
            <div style={{ marginBottom: "20px" }}>
              <span
                style={{ borderRight: "1px solid black", paddingRight: "5px" }}
              >
                Remaining prepaid balance: <b>${isLoading?"loading...":clientPrepaidBalance}</b>
              </span>

              <Button color="fourth" onClick={() => navigate("/checkout")}>Prepay Online</Button>
            </div>

            <Box sx={{ width: "100%", mb: 2 }}>
              <Toolbar
                className={
                  isOpenSidebar
                    ? "transactionToolbarSidebar"
                    : "transactionToolbar"
                }
              >
                <Button variant="outlined" className="export">
                  export as .csv
                </Button>

                <div className="timeForm">
                  <div style={{ marginRight: "5px" }}>
                    <span style={{ marginRight: "5px" }}>Show from:</span>
                    <Select defaultValue="01" className="transactionSelect">
                      {monthsList.map((month, index) => {
                        return (
                          <option key={index} value={month.value}>
                            {month.label}
                          </option>
                        );
                      })}
                    </Select>

                    <Select defaultValue="2022" className="transactionSelect">
                      <option value="2022">2022</option>
                    </Select>
                  </div>

                  <div style={{ marginRight: "5px" }}>
                    <span style={{ marginRight: "5px" }}>To:</span>
                    <Select defaultValue="01" className="transactionSelect">
                      {monthsList.map((month, index) => {
                        return (
                          <option key={index} value={month.value}>
                            {month.label}
                          </option>
                        );
                      })}
                    </Select>

                    <Select defaultValue="2022" className="transactionSelect">
                      <option value="2022">2022</option>
                    </Select>
                  </div>

                  <Button variant="outlined" sx={{ height: "30px" }}>
                    Refresh
                  </Button>
                </div>
              </Toolbar>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          sx={{
                            minWidth: "75px !important",
                          }}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>Balance as of Nov-01-22 </TableCell>
                        <TableCell>$0.00</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </div>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
