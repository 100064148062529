import { useEffect, React, useState } from "react";
import {
  Alert,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Card,
  CardMedia,
  Button,
  FormControl,
  InputLabel,
  Input,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ThemeProvider } from "@mui/material/styles";
import logo from "../assets/img/logo.png";
import theme from "../theme";
import Requests from "../logic/Requests";
import { useNavigate } from "react-router-dom";
import "../App.css";
import useZustant from "../hooks/useZustant";
import useShow from "../hooks/useShow";
import RecoverPasswordModal from "../components/RecoverPasswordModal";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import Config from "../config";
import Utils from "../utils";

export default function Login() {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  useEffect(() => {
    document.title = "Login - Focus Forward Tops";

    const url = new URL(window.location.href);
    const urlToken = url.searchParams.get("token");

    if (urlToken) {
      setToken(urlToken);
    }
  }, []);

  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();

  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };

  const [errorFields, setErrorFields] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  let alertError;
  const { user, getUser } = useZustant();
  const { show, open, close } = useShow();

  const {
    show: showRecover,
    open: openRecover,
    close: closeRecover,
  } = useShow();

  const changePassword = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData(e.currentTarget);

      const password = data.get("password");
      const confirmPassword = data.get("confirmPassword");

      if (password !== confirmPassword) {
        handleAlert("warning", "Passwords do not match");
        return;
      } else {
        Requests.changePassword({
          name: "",
          token: token,
          password: password,
        }).then((res) => {
          if (res.status === 200) {
            handleAlert("success", "Password changed successfully");
            navigate("/");
            window.location.reload();
          }
        });
      }
    } catch (error) {
      handleAlert("error", "There was an error updating the information");
    }
  };

  const ResetPassword = () => {
    return (
      <Box
        className="boxModal"
        sx={{
          p: 3,
        }}
      >
        <h1 id="modal-modal-title">Change Password</h1>

        <form className="formModal" onSubmit={changePassword}>
          <FormControl fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input id="password" name="password" type="password" required />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
            <Input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              required
            />
          </FormControl>
          <div className="buttonsModalContainer">
            <Button type="submit" variant="contained" color="primary">
              Change Password
            </Button>
          </div>
        </form>
      </Box>
    );
  };

  const { show: showError, open: openError, close: closeError } = useShow();
  const handleSubmit = async (event) => {
    document.cookie = `comesFromTranscriberHub=true; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${Config.cookieDomain}; path=/;"`;
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const userData = {
      username: data.get("email"),
      password: data.get("password"),
    };
    event.preventDefault();
    if (userData.username === "" || userData.password === "") {
      setErrorFields(true);
      setLoading(false);
    } else {
      try {
        const login = await Requests.login(userData);
        if (login.status === 200) {
          await getUser();
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
        setError(true);
      }
    }
  };

  const recoverPassword = (e) => {
    e.preventDefault();
    try {
      const data = new FormData(e.currentTarget);
      const email = data.get("email");

      Requests.ResetPassword(email, true).then((res) => {
        if (res.status === 200) {
          openRecover();
          close();
        } else {
          openError();
        }
      });
    } catch (error) {
      openError();
    }
  };

  if (error) {
    alertError = (
      <Alert variant="outlined" severity="error">
        We were unable to log you in. Please verify your username and password.
      </Alert>
    );
  } else {
    alertError = <div />;
  }

  return (
    <ThemeProvider theme={theme}>
      <RecoverPasswordModal
        show={show}
        close={close}
        recoverPassword={recoverPassword}
      />
      <CustomSnackBarAlert show={showError} close={closeError} type="error">
        There was an error sending the email
      </CustomSnackBarAlert>

      <CustomSnackBarAlert
        show={showRecover}
        close={closeRecover}
        type="success"
      >
        A recovery email has been sent.
      </CustomSnackBarAlert>

      <CustomSnackBarAlert
        show={showAlert}
        close={closeAlert}
        type={currentAlertState.type}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      {token ? (
        <ResetPassword />
      ) : (
        <div className="App">
          <Card
            component="main"
            style={{ width: 500, padding: 40 }}
            elevation={20}
          >
            <CardMedia component="img" width="" image={logo} />

            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              style={{ marginTop: 10 }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                {alertError}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  variant="filled"
                  autoComplete="email"
                  error={errorFields}
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  variant="filled"
                  type="password"
                  error={errorFields}
                  id="password"
                  autoComplete="current-password"
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading={loading}
                >
                  Login
                </LoadingButton>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2" onClick={() => open()}>
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>

          <Typography
            variant="body2"
            color="white"
            align="center"
            style={{ marginTop: 10, color: "white" }}
          >
            {"© 2020 Focus Forward, LLC. All rights reserved. "}
          </Typography>
        </div>
      )}
    </ThemeProvider>
  );
}
