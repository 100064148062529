import { ThemeProvider } from "@emotion/react";
import theme from "../theme";
import { Box, Container } from "@mui/system";
import {
  Typography,
  Button,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  styled,
  InputBase,
  FormControl,
  InputLabel,
  Input,
  IconButton,
} from "@mui/material";
import "../App.css";
import ChangePasswordModal from "../components/ChangePassword";
import useShow from "../hooks/useShow";
import useZustant from "../hooks/useZustant";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faCopy,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import Requests from "../logic/Requests";
import useFetch from "../hooks/useFetch";
import Utils from "../utils";

export default function MyAccount() {
  const { user, setCurrentClientPage, changeIsInClientsSettings } =
    useZustant();
  const { open, close, show } = useShow();
  const [editingEmail, setEditingEmail] = useState(false);

  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });

  const apikey = useRef(null);

  const {
    data: clientInformation,
    isLoading,
    reFetch,
  } = useFetch(Requests.getClient, user.client);

  const { show: showApiKey, toggle } = useShow();

  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();

  const RenderApiKey = () => {
    if (showApiKey) {
      if (clientInformation.secretkey) {
        return (
          <span style={{ fontSize: "12px" }} ref={apikey}>
            {clientInformation.secretkey}
          </span>
        );
      } else {
        return <span>**********</span>;
      }
    } else {
      return <b style={{ fontSize: "16px", userSelect: "none" }}>**********</b>;
    }
  };

  const copyApiKey = () => {
    if (apikey.current) {
      navigator.clipboard.writeText(apikey.current.innerText);
      handleAlert("success", "API Key copied to clipboard");
    }
  };

  const regenerateApiKey = async () => {
    try {
      await Requests.generateAPIKey(clientInformation.id);
      reFetch();
    } catch (err) {
      handleAlert(
        "error",
        "There was a problem regenerating your api key, try again"
      );
    }
  };

  const handleToggle = () => {
    if (!clientInformation.secretkey) {
      handleAlert(
        "error",
        "There was a problem retrieving your api key, try again or regenerate it."
      );
    } else {
      toggle();
    }
  };

  useEffect(() => {
    setCurrentClientPage("My Account");
    changeIsInClientsSettings(true);
  }, []);

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };

  const CustomInput = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
      marginTop: theme.spacing(3),
      position: "relative",
      borderRadius: "4px",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      borderBottom: "none",
      border: "1px solid #d3d3d3",
      fontSize: 16,
      padding: "5px 6px",
      height: "36px",
      boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.1)",
      width: "100%",

      "&:focus": {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    },
  }));

  const AccountInformation = () => {
    return (
      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Account Number</TableCell>
                  <TableCell>123456789</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{user.mail}</TableCell>
                  <TableCell align="right">
                    <Button
                      sx={{
                        color: theme.palette.fourth.main,
                        fontWeight: 700,
                      }}
                      onClick={() => setEditingEmail(true)}
                    >
                      change
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Password</TableCell>
                  <TableCell>**********</TableCell>
                  <TableCell align="right">
                    <Button
                      sx={{
                        color: theme.palette.fourth.main,
                        fontWeight: 700,
                      }}
                      onClick={() => open()}
                    >
                      Change
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>API Key</TableCell>
                  <TableCell>
                    <RenderApiKey />
                    {showApiKey ? (
                      <IconButton onClick={copyApiKey}>
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ fontSize: "16px" }}
                        />
                      </IconButton>
                    ) : null}
                    <IconButton onClick={handleToggle}>
                      <FontAwesomeIcon
                        icon={showApiKey ? faEyeSlash : faEye}
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      sx={{
                        color: theme.palette.fourth.main,
                        fontWeight: 700,
                      }}
                      onClick={regenerateApiKey}
                    >
                      Regenerate
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Account Closure</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <Button
                      sx={{
                        color: theme.palette.fourth.main,
                        fontWeight: 700,
                      }}
                    >
                      close account
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Divider />
      </Box>
    );
  };

  const EditEmail = () => {
    const handleSubmit = (e) => {
      e.preventDefault();
      try {
        const Data = new FormData(e.currentTarget);

        const email = Data.get("email");
        const confirmEmail = Data.get("newemail");

        if (!Utils.validateEmail(email)) {
          handleAlert("error", "Please enter a valid email");
          return;
        }

        if (email !== confirmEmail) {
          handleAlert("error", "Emails do not match");
          return;
        } else {
          Requests.saveUser(user.id, { mail: email }).then((res) => {
            if (res.status === 200) {
              handleAlert("success", "Email updated successfully");
              setEditingEmail(false);
            } else {
              handleAlert("error", "Error updating email");
            }
          });
        }
      } catch (error) {}
    };

    return (
      <Box className="emailForm">
        <Button
          onClick={() => setEditingEmail(false)}
          color="fourth"
          startIcon={
            <FontAwesomeIcon icon={faBackward} style={{ fontSize: "14px" }} />
          }
        >
          Return to settings
        </Button>

        <form
          style={{
            width: "100%",
            height: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
          onSubmit={handleSubmit}
        >
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="currentEmail" shrink>
              OLD EMAIL
            </InputLabel>
            <Input
              name="currentEmail"
              id="currentEmail"
              value={user.mail}
              onlyread
              disabled
            />
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="email" shrink>
              NEW EMAIL
            </InputLabel>
            <CustomInput name="email" id="email" />
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="newemail" shrink>
              RE-ENTER YOUR NEW EMAIL
            </InputLabel>
            <CustomInput name="newemail" id="newEmail" />
          </FormControl>

          <Button variant="contained" sx={{ width: "150px" }} type="submit">
            Change Mail
          </Button>
        </form>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={showAlert}
        close={closeAlert}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <Container sx={{ width: "100%", pt: 3 }}>
        <Typography variant="h1" sx={{ mb: 3 }}>
          My Account
        </Typography>
        <ChangePasswordModal
          closeModal={close}
          currentUser={user}
          showModal={show}
          handleAlert={handleAlert}
        />
        {editingEmail ? <EditEmail /> : <AccountInformation />}
      </Container>
    </ThemeProvider>
  );
}
