import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Typography,
  FormControlLabel,
  Grid,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  TextareaAutosize,
  DialogActions,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import useShow from "../../hooks/useShow";
import CustomSnackBarAlert from "../global/CustomSnackBarAlert";
import "../../App.css";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Requests from "../../logic/Requests";
import { useParams } from "react-router-dom";
import Config from "../../config/index";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

function AprroveUnit(props) {
  let { idUnit, idProject, idTranscript } = useParams();
  let { length } = props;
  const gradeItems = ["A", "B", "C", "D", "F"];
  const [unitTime, setUnitTime] = React.useState(
    dayjs().set("hour", 0).set("minute", 0).set("second", length)
  );
  const [payAdjust, setPayAdjust] = React.useState("");
  const [checkScore, setCheckScore] = React.useState([
    { text: "Late Assignment", checked: false },
    { text: "Confirmed and Did Not Complete", checked: false },
    { text: "No Action", checked: false },
    { text: "Sloppy Verbatim", checked: false },
    { text: "Did Not Revise", checked: false },
    { text: "Failed to follow instructions", checked: false },
    { text: "Submitted partial labeled as complete?", checked: false },
    { text: "Wrong format?", checked: false },
    { text: "Speech attribution wrong?", checked: false },
    { text: "Sparse Notes", checked: false },
    { text: "Terminology Super-Star", checked: false },
    { text: "Reliable", checked: false },
    { text: "Same-Day Savior", checked: false },
    { text: "Overnight Hero", checked: false },
    { text: "Format Specialist", checked: false },
    { text: "Quick and Accurate", checked: false },
    { text: "Very detailed note-taking", checked: false },
  ]);
  const [grade, setGrade] = React.useState("");
  const { show, open, close } = useShow();
  const { show: showAlert, open: openAlert, close: closeAlert } = useShow();
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("error");
  const [feedback, setFeedback] = React.useState("");

  const handleSubmit = async () => {
    try {
      const hours = unitTime.$H;
      const minutes = unitTime.$m;
      const seconds = unitTime.$s;
      const duration = hours * 3600 + minutes * 60 + seconds;
      let doc = {
        data: {
          grade: grade || null,
          notes: feedback || "",
          payAdjust: payAdjust || null,
          unitTime: duration || null,
          score_card: checkScore.filter((e) => e.checked == true) || [],
        },
      };
      await Requests.approveTranscript(idUnit, doc);

      setAlertType("success");
      setAlertContent("Approved unit");
      openAlert();
      close();
      window.location.replace(
        `${Config.oldUI}/projects/${idProject}/transcript/${idTranscript}`
      );
    } catch (error) {
      console.error(error);
      setAlertType("error");
      setAlertContent("An error occurred while submitting the unit");
      openAlert();
    }
  };

  const setValue = (index) => {
    let auxArray = checkScore;
    auxArray[index].checked = !auxArray[index].checked;
    setCheckScore((oldArray) => [...auxArray]);
  };

  const handleGrade = (event) => {
    setGrade(event.target.value);
  };

  return (
    <>
      <CustomSnackBarAlert show={showAlert} close={closeAlert} type={alertType}>
        {alertContent}
      </CustomSnackBarAlert>
      <Button
        variant="contained"
        onClick={open}
        style={{ backgroundColor: "#1976D2" }}
      >
        Approve
      </Button>
      <Dialog open={show} maxWidth="lg" onClose={close}>
        <DialogTitle sx={{ textAlign: "center" }}>
          Transcriber Feedback
        </DialogTitle>
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
          }}
        >
          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} style={{ paddingBottom: 30 }}>
                <Typography variant="body1" sx={{ my: 1, fontWeight: "bold" }}>
                  Audio Length Transcribed
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      value={unitTime ? unitTime : ""}
                      onChange={(newValue) => {
                        setUnitTime(newValue);
                      }}
                      placeholder="hh:mm:ss"
                      format="HH:mm:ss"
                      mask="__:__:__"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4} style={{ paddingBottom: 30 }}>
                <Typography variant="body1" sx={{ my: 1, fontWeight: "bold" }}>
                  Grade
                </Typography>
                <Select
                  style={{ width: "100%" }}
                  value={grade}
                  onChange={handleGrade}
                >
                  {gradeItems.map((item, key) => (
                    <MenuItem key={key} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={4} style={{ paddingBottom: 30 }}>
                <Typography variant="body1" sx={{ my: 1, fontWeight: "bold" }}>
                  Pay Adjust (%)
                </Typography>
                <TextField
                  required
                  type="number"
                  style={{ width: "100%" }}
                  value={payAdjust}
                  onChange={(e) => {
                    setPayAdjust(e.target.value);
                  }}
                />
              </Grid>
              {checkScore.map((data, index) => (
                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  key={index}
                >
                  <FormControlLabel
                    label={data.text}
                    control={
                      <Checkbox
                        checked={data.checked}
                        onChange={() => setValue(index)}
                      />
                    }
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ my: 1, fontWeight: "bold" }}>
                  Optional: Any comments or feedback for the transcriber about
                  this transcript?
                </Typography>
                <TextareaAutosize
                  minRows={5}
                  style={{ width: "100%" }}
                  value={feedback}
                  onChange={(e) => {
                    setFeedback(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <DialogActions>
            <Button
              onClick={close}
              variant="contained"
              sx={{ color: "white !important" }}
              color="red"
            >
              {" "}
              Cancel
            </Button>
            <Button onClick={handleSubmit} autoFocus variant="contained">
              Submit
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}

export default AprroveUnit;
