import { Navigate } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import useZustant from "../hooks/useZustant";
import Config from "../config/index";
import Requests from "../logic/Requests";


export default function LoggedRoutes({ redirectTo = "/", children }) {
  const { user, clearUser } = useZustant();
  if (!user && user !== undefined) {
    return children;
  }
  if (user.rolename === "Admin" || user.rolename === "PM") {
    Requests.getUser()
      .then((data) => {
        return window.location.replace(`${Config.oldUI}/dashboard`);
      })
      .catch((err) => {
        console.error(err);
        clearUser();
        return children;
      });
  } else if (user.rolename === "Transcriber") {
    return <Navigate to={`/mywork/${user._id}`} replace />;
  } else if (user.rolename === "Client") {
    return <Navigate to={`/filemanagement`} replace />;
  } else if (user.rolename === "QA") {
    Requests.getUser()
      .then((data) => {
        return window.location.replace(`${Config.oldUI}/projects/qa`);
      })
      .catch((err) => {
        console.error(err);
        clearUser();
        return children;
      });
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress color="fourth" />
        </Box>
      </ThemeProvider>
    );
  }
}
