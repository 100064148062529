import { useEffect } from "react";
import useZustant from "../hooks/useZustant";
import {
  Button,
  Container,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import theme from "../theme";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

export default function SearchFiles() {
  const { setCurrentClientPage, changeIsInClientsSettings } = useZustant();
  const navigate = useNavigate();

  const mobile = useMediaQuery("(max-width:400px)");

  useEffect(() => {
    document.title = "Search Files - Focus Forward";
    setCurrentClientPage("Search Files");
    changeIsInClientsSettings(false);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ width: "100%", paddingTop: 4 }}>
        <Typography variant="h1" sx={{ marginBottom: 3 }}>
          Search Files
        </Typography>

        <div
          style={{
            display: "flex ",
            alignItems: "center",
            width: "100%",
          }}
        >
          <TextField
            type="text"
            placeholder="Search Files..."
            style={{
              flexGrow: 10,
              marginRight: "10px",
            }}
          />
          {mobile ? (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                flexGrow: 1,
                maxHeight: "37px !important",
                maxWidth: "160px",
              }}
              startIcon={<SearchIcon />}
              fontSize={mobile ? "12px" : "16px"}
            >
              Search
            </Button>
          )}
        </div>
      </Container>
    </ThemeProvider>
  );
}
