import React, { useEffect, useState, useCallback } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import {
  Box,
  Typography,
  useMediaQuery,
  Button,
  Menu,
  MenuItem,
  Container,
} from "@mui/material";
import useZustant from "../hooks/useZustant";
import Requests from "../logic/Requests";
import Utils from "../utils";
import AWS from "aws-sdk";
import "../App.css";
import FileManagementTable from "../components/fileManager/FileManagementTable";
import UndoIcon from "@mui/icons-material/Undo";
import useShow from "../hooks/useShow";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import Config from "../config";
import QueueIcon from "@mui/icons-material/Queue";
import { useNavigate } from "react-router-dom";
import CreateFolderModal from "../components/fileManager/createFolderModal";

AWS.config.update({
  region: Config.awsRegion,
  accessKeyId: Config.accessKey,
  secretAccessKey: Config.secretAccessKey,
});
const s3 = new AWS.S3();

function FileManagement() {
  const { baseNameFolder, basename } = Utils;
  const [files, setFiles] = useState([]);
  const [filesSearch, setFilesSearch] = useState([]);
  const [folderPrefix, setFolderPrefix] = useState([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };

  const matches = useMediaQuery("(max-width:1003px)");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { show: showModal, close: closeModal, open: openModal } = useShow();

  const {
    show: showMoveModal,
    close: closeMoveModal,
    open: openMoveModal,
  } = useShow();

  const [searchQuery, setSearchQuery] = useState("");
  const {
    user,
    currentFolderPrefix,
    placeFolderPrefix,
    setCurrentClientPage,
    changeIsInClientsSettings,
    changeNewOrderType,
  } = useZustant();
  const navigate = useNavigate();

  const changePrefix = (folderPrefix, row) => {
    if (row.isDir && !isLoadingFiles) {
      setFolderPrefix([
        ...folderPrefix,
        { id: row.id, name: row.name, isDir: true },
      ]);
      placeFolderPrefix([
        ...folderPrefix,
        { id: row.id, name: row.name, isDir: true },
      ]);
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
    setFilesSearch([]);
  };

  const fetchS3BucketContents = async (bucket, prefix, optionalQuery) => {
    if (!optionalQuery && optionalQuery !== "") {
      const response = await s3
        .listObjectsV2({
          Bucket: bucket,
          Delimiter: "/",
          Prefix: prefix !== "/" ? prefix : "",
        })
        .promise();

      const files = [];
      const s3Objects = response.Contents;
      const s3Prefixes = response.CommonPrefixes;

      if (s3Objects) {
        files.push(
          ...s3Objects.map((object) => ({
            id: object.Key ? object.Key : object.Size,
            name: basename(object.Key) ? basename(object.Key) : object.modDate,
            modDate: object.LastModified,
            size: object.Size,
          }))
        );
      }

      if (s3Prefixes) {
        files.push(
          ...s3Prefixes.map((prefix) => ({
            id: prefix.Prefix,
            name: baseNameFolder(prefix.Prefix),
            isDir: true,
          }))
        );
      }

      return files;
    } else {
      const folders = files.filter((folder) => folder.isDir);
      folders.push(folderPrefix[folderPrefix.length - 1]);
      const aux = [];

      const promiseArray = folders.map(async (folder) => {
        const response = await s3
          .listObjectsV2({
            Bucket: bucket,
            Delimiter: "/",
            Prefix: folder.id,
          })
          .promise();

        const files = [];
        const s3Objects = response.Contents;

        if (s3Objects) {
          files.push(
            ...s3Objects.map((object) => ({
              id: object.Key ? object.Key : object.Size,
              name: basename(object.Key)
                ? basename(object.Key)
                : object.modDate,
              modDate: object.LastModified,
              size: object.Size,
            }))
          );
        }

        return files;
      });

      await Promise.all(promiseArray).then((res) => {
        res.forEach((file) => {
          file.forEach((f) => {
            aux.push(f);
          });
        });
      });

      const filterFiles = aux.filter((file) => {
        if (file.name) {
          return file.name.toLowerCase().includes(optionalQuery.toLowerCase());
        } else {
          return false;
        }
      });

      return filterFiles;
    }
  };

  const goBack = () => {
    if (folderPrefix.length > 1) {
      const aux = [].concat(folderPrefix);
      aux.length = folderPrefix.length - 1;
      setFolderPrefix(aux);
      placeFolderPrefix(aux);
    }
  };

  const filesAction = (option, key) => {
    if (option === "Download") {
      try {
        const url = Requests.downloadObjects([key]);
        window.location = url;
      } catch (error) {
        handleAlert("error", "There was a problem downloading file(s)");
      }
    }
  };

  const goFolderChainSpecificFolder = (folder) => {
    const index = folderPrefix.indexOf(folder);
    const aux = [].concat(folderPrefix);

    aux.length = index + 1;
    setFolderPrefix(aux);
    placeFolderPrefix(aux);
  };

  const FolderChain = () => {
    return (
      <Box
        className="folderChain"
        sx={{
          pt: 6,
          pb: 3,
        }}
      >
        <UndoIcon
          sx={{
            marginRight: "10px",
            cursor: folderPrefix.length > 1 ? "pointer" : "default",
          }}
          onClick={() => {
            goBack();
          }}
        />

        {folderPrefix.map((folder, index) => {
          return (
            <Typography
              variant="h2"
              key={folder.id}
              onClick={() => {
                goFolderChainSpecificFolder(folder);
              }}
            >
              {index === 0 ? "File Manager" : folder.name} / &nbsp;
            </Typography>
          );
        })}
      </Box>
    );
  };

  useEffect(() => {
    document.title = "File Management - Focus Forward Tops";
    setCurrentClientPage("My Files");
    changeIsInClientsSettings(false);

    try {
      let data = [];

      Requests.getUserFolders(user.id).then((res) => {
        res.data.forEach((folder) => {
          let object = {};
          object.isFile = false;
          object.name = folder.name;
          object.public = false;
          object.url = `https://${Config.awsBucketName}.s3.amazonaws.com/${folder.path}`;
          object.key = folder.path;
          data.push({
            id: object.key,
            name: object.name,
            isDir: true,
          });
        });

        setFolderPrefix(data);

        setFolders(data);
        if (currentFolderPrefix.length > 0) {
          setFolderPrefix(currentFolderPrefix);
        } else {
          placeFolderPrefix(data);
        }
      });
    } catch (error) {
      handleAlert("error", "There was a problem loading folders");
      setFiles([]);
    }
  }, []);

  useEffect(() => {
    if (folderPrefix.length > 0) {
      updateFiles();
    }
  }, [folderPrefix, setFiles]);

  const downloadSelectedFiles = (files) => {
    try {
      const arrayOfIds = files.map((file) => file.id);
      const url = Requests.downloadObjects(arrayOfIds);
      window.location = url;
    } catch (error) {
      handleAlert("error", "There was a problem downloading file(s)");
    }
  };

  const updateFiles = () => {
    try {
      setIsLoadingFiles(true);
      fetchS3BucketContents(
        Config.awsBucketName,
        folderPrefix[folderPrefix.length - 1].id
      ).then((res) => {
        if (folderPrefix.length - 1 === 0) {
          res.shift();
          setFiles(res);
        } else {
          setFiles(res);
        }
        setIsLoadingFiles(false);
      });
    } catch (e) {
      setIsLoadingFiles(false);
      setFiles([]);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={showAlert}
        close={closeAlert}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <CreateFolderModal
        show={showModal}
        close={closeModal}
        path={
          folderPrefix.length > 0
            ? folderPrefix[folderPrefix.length - 1].id
            : null
        }
        bucket={s3}
        handleAlert={handleAlert}
        updateFiles={updateFiles}
      />

      <Container style={{ width: "100%", maxWidth: "100%" }}>
        <div
          style={
            matches
              ? { display: "flex", flexDirection: "column", width: "100%" }
              : {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }
          }
        >
          <FolderChain />

          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              className="uploadFiles"
              color="success"
              style={{ height: "50px", width: "250px" }}
              startIcon={<QueueIcon />}
              onClick={handleClick}
            >
              Place new order
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  changeNewOrderType("human");
                  navigate("/uploadFiles");
                }}
              >
                Transcription
              </MenuItem>

              <MenuItem
                onClick={() => {
                  changeNewOrderType("automated");
                  navigate("/uploadFiles");
                }}
              >
                Automated Transcription
              </MenuItem>
            </Menu>
          </div>
        </div>
        <FileManagementTable
          changePrefix={changePrefix}
          files={files}
          folderPrefix={folderPrefix}
          isLoading={isLoadingFiles}
          searchQuery={searchQuery}
          filesSearch={filesSearch}
          clearSearch={clearSearch}
          filesAction={filesAction}
          downloadSelectedFiles={downloadSelectedFiles}
          open={openModal}
          updateFiles={updateFiles}
          folder={folders[0]}
          showMoveModal={showMoveModal}
          closeMoveModal={closeMoveModal}
          openMoveModal={openMoveModal}
          handleAlert={handleAlert}
          handleAction={handleAlert}
        />
      </Container>
    </ThemeProvider>
  );
}

export default FileManagement;
