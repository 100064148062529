import { React, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import {
  Box,
  Typography,
  Button,
  Radio,
  FormControl,
  InputLabel,
  InputBase,
  styled,
  Select,
  FormLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import ComputerIcon from "@mui/icons-material/Computer";
import "../App.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloudIcon from "@mui/icons-material/Cloud";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { useDropzone } from "react-dropzone";
import Config from "../config";
import AWS from "aws-sdk";
import Utils from "../utils";
import { useNavigate } from "react-router-dom";
import useZustant from "../hooks/useZustant";
import Requests from "../logic/Requests";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import useShow from "../hooks/useShow";
import awsUtils from "../awsUtils";
import ListOfFiles from "../components/ListOfFiles";
import SquarePayment from "../components/SquarePayment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import {
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { monthsList, yearList } from "../lists";

AWS.config.update({
  region: Config.awsRegion,
  accessKeyId: Config.accessKey,
  secretAccessKey: Config.secretAccessKey,
});
const s3 = new AWS.S3();

export default function UploadFiles() {
  const { baseNameFolder } = Utils;
  const { emptyS3Directory, moveItem } = awsUtils;
  const { user, currentFolderPrefix } = useZustant();
  const [isDragHover, setIsDragHover] = useState(false);
  const [files, setFiles] = useState([]);
  const [folder, setFolder] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const { show, open, close } = useShow();
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const [filesObject, setFilesObject] = useState({});

  const handleFiles = (files) => {
    setFilesObject(files);
  };
  const CustomInput = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
      marginTop: theme.spacing(3),
      position: "relative",
      borderRadius: "4px",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      borderBottom: "none",
      border: "1px solid #d3d3d3",
      fontSize: 16,
      padding: "5px 6px",
      height: "36px",
      boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.1)",

      "&:focus": {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    },
  }));

  const BootstrapInput = styled(InputBase)(() => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #d3d3d3",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.1)",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      height: "25px",

      "&:focus": {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    },
  }));

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    open();
  };
  const [folders, setFolders] = useState([]);

  const fetchS3Folders = async (prefix) => {
    const response = await s3
      .listObjectsV2({
        Bucket: Config.awsBucketName,
        Delimiter: "/",
        Prefix: prefix !== "/" ? prefix : "",
      })
      .promise();

    const folders = [];

    const s3Prefixes = response.CommonPrefixes;

    if (s3Prefixes) {
      folders.push(
        ...s3Prefixes.map((prefix) => ({
          id: prefix.Prefix,
          name: baseNameFolder(prefix.Prefix),
          isDir: true,
        }))
      );
    }
    return folders;
  };

  const [isInCheckout, setIsInCheckout] = useState(false);

  const goToCheckout = () => {
    setIsInCheckout(true);
  };
  const exitCheckout = () => {
    setIsInCheckout(false);
  };

  useEffect(() => {
    document.title = "Files Upload";

    try {
      let data = [];

      Requests.getUserFolders(user.id).then((res) => {
        res.data.forEach((folder) => {
          let object = {};
          object.isFile = false;
          object.name = folder.name;
          object.public = false;
          object.url = `https://${Config.awsBucketName}.s3.amazonaws.com/${folder.path}`;
          object.key = folder.path;
          data.push({
            id: object.key,
            name: object.name,
            isDir: true,
          });
        });

        setFolder(data[0]);

        fetchS3Folders(data[0].id).then((res) => {
          setFolders(res);

          emptyS3Directory(
            Config.awsBucketName,
            data[0].id + user.id + "_Temp/"
          );
        });
      });
    } catch (error) {
      setFolder(null);
    }
  }, []);

  const navigate = useNavigate();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles.map((file) => file)]);
      setIsDragHover(false);
    },
  });

  const deleteFile = (index) => {
    const newArr = files.filter((file, i) => i !== index);
    setFiles(newArr);
  };

  const onChangeFileButtonInput = (e) => {
    const file = e.target.files[0];
    setFiles([...files, file]);
  };

  const BasicInputFile = () => {
    return (
      <div className="insideFile">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <ComputerIcon fontSize="large" color="primary" />
            <ArrowForwardIcon fontSize="large" color="primary" />
            <CloudIcon fontSize="large" color="primary" />
          </div>

          <Typography style={{ color: "black" }} variant="p">
            Upload files from your computer or drag and drop here
          </Typography>
        </div>

        <Button
          variant="contained"
          color="primary"
          component="label"
          className="uploadButton"
        >
          Upload
          <input
            hidden
            multiple
            type="file"
            onChange={(e) => onChangeFileButtonInput(e)}
          />
        </Button>
      </div>
    );
  };

  const DropInput = () => {
    return (
      <div
        style={
          isDragHover
            ? {
                display: "flex",
                flexDirection: "column",
                alingItems: "center",
                pointerEvents: "none",
              }
            : {
                display: "none",
              }
        }
      >
        <CopyAllIcon
          fontSize="inherit"
          sx={{
            fontSize: "70px",
            pointerEvents: "none",
            margin: "auto",
            marginBottom: "30px",
          }}
        />
        <Typography
          variant="h2"
          sx={{ fontWeight: "200", pointerEvents: "none" }}
        >
          Drop files here
        </Typography>
        <input hidden multiple type="file" {...getInputProps()} />
      </div>
    );
  };

  const uploadFiles = async () => {
    await Promise.all(
      filesObject.completedUploads.map((file) => {
        return moveItem(
          { id: file.key, isDir: false, name: file.fileName },
          currentFolderPrefix[currentFolderPrefix.length - 1].id
        );
      })
    );
    navigate("/filemanagement");
  };

  // transcription price in dollar/min

  const basicRates = { automated: 0.25, human: 1.5 };

  // estimated DeliveryTime

  const estimatedDeliveryTime = [
    {
      min: 0,
      max: 10,
      medianTurnaroundTime: "2 hours",
      maxTurnaroundTime: "24 hours",
      rushMedianTurnaroundTime: "1 hour",
      rushMaxTurnaroundTime: "12 hours",
    },
    {
      min: 10,
      max: 30,
      medianTurnaroundTime: "8 hours",
      maxTurnaroundTime: "24 hours",
      rushMedianTurnaroundTime: "2 hours",
      rushMaxTurnaroundTime: "12 hours",
    },
    {
      min: 30,
      max: 60,
      medianTurnaroundTime: "16 hours",
      maxTurnaroundTime: "48 hours",
      rushMedianTurnaroundTime: "5 hours",
      rushMaxTurnaroundTime: "24 hours",
    },
    {
      min: 60,
      max: 120,
      medianTurnaroundTime: "24 hours",
      maxTurnaroundTime: "72 hours",
      rushMedianTurnaroundTime: "5 hours",
      rushMaxTurnaroundTime: "24 hours",
    },
    { min: 120,
      max: 9999,
      medianTurnaroundTime: "48 hours",
      maxTurnaroundTime: "72 hours",
      rushMedianTurnaroundTime: "5 hours",
      rushMaxTurnaroundTime: "24 hours",
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={show}
        close={close}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <Box sx={{ mt: 3, mx: { xs: 2, md: 6 } }}>
        <Typography variant="h1" sx={{ ml: 5 }}>
          {isInCheckout ? "Paymet Details" : "Upload Files"}
        </Typography>

        <Button
          sx={{ ml: 5, mt: 2 }}
          onClick={() => navigate("/filemanagement")}
        >
          Back to my files
        </Button>

        <Box
          {...(files.length === 0 ? { ...getRootProps() } : null)}
          className={
            files.length > 0
              ? "filesToUpload"
              : isDragHover
              ? "dragAndDrop"
              : "uploadFilesInputContainer"
          }
          sx={isInCheckout ? { display: "none" } : null}
          onDragOver={() => setIsDragHover(true)}
          onDragLeave={() => setIsDragHover(false)}
        >
          {files.length > 0 ? (
            <ListOfFiles
              basicRates = {basicRates}
              estimatedDeliveryTime = {estimatedDeliveryTime} 
              files={files}
              getRootProps={getRootProps}
              isDragHover={isDragHover}
              DropInput={DropInput}
              folder={folder}
              folders={folders}
              s3={s3}
              deleteFile={deleteFile}
              fetchS3Folders={fetchS3Folders}
              isLoading={isLoading}
              handleAlert={handleAlert}
              goToCheckout={goToCheckout}
              handleFiles={handleFiles}
            />
          ) : isDragHover ? (
            <DropInput />
          ) : (
            BasicInputFile()
          )}
        </Box>

        <Box
          sx={
            !isInCheckout
              ? { display: "none" }
              : {
                  display: "flex",
                  mt: 8,
                  width: "100%",
                  justifyContent: "space-around",
                }
          }
        >
          <Box>
            <div style={{ marginBottom: "10px" }}>
              <span
                variant="h3"
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
              >
                Payment Information for My Transcript Order
              </span>
            </div>

            <Box className="paymentCard">
              <SquarePayment 
                amount={filesObject.total}
                currency={"USD"}
                afterPayment={()=>{setIsInCheckout(false)}} />
            </Box>

            <Box className="orderContainer">
              <Button variant="contained" onClick={() => uploadFiles()}>
                Order Transcript
              </Button>
            </Box>
          </Box>

          <Box>
            <div style={{ marginBottom: "10px" }}>
              <span
                style={{
                  fontSize: "20px",
                  color: "transparent",
                }}
              >
                P
              </span>
            </div>
            <Box className="filesDetails">
              <div className="item">
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    color: theme.palette.primary.main,
                    fontSize: "17px",
                  }}
                >
                  My Transcript Order
                </Typography>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => exitCheckout()}
                >
                  EDIT &gt;
                </span>
              </div>
              <Divider />

              {filesObject.completedUploads
                ? filesObject.completedUploads.map((file, index) => (
                    <div key={index} >
                      <div className="item file" >
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: 300,
                            fontSize: "17px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px",
                            color: theme.palette.fourth.main,
                          }}
                        >
                          {file.fileName}
                        </Typography>
                        <span className="value">${file.cost} </span>
                      </div>
                      <Divider/>
                    </div>
                  ))
                : null}

              <div className="item" style={{ paddingBottom: "10-px" }}>
                <Typography variant="43" sx={{ fontWeight: 300 }}>
                  SUBTOTAL <b style={{ fontSize: "11px" }}>($1.50/MIN)</b>
                </Typography>
                <span className="value">
                  $
                  {filesObject.subtotal
                    ? filesObject.subtotal.toFixed(2)
                    : null}
                </span>
              </div>

              {filesObject.checkedOptions
                ? filesObject.checkedOptions.map((option, index) => (
                    <div className="item check" key={index}>
                      <Typography variant="43" sx={{ fontWeight: 300 }}>
                        {option.label}
                      </Typography>
                      <span className="value">${option.value.toFixed(2)}</span>
                    </div>
                  ))
                : null}
              <Divider sx={{ mt: 2 }} />

              <div className="item">
                <Typography variant="43" sx={{ fontWeight: 300 }}>
                  SALES TAX
                </Typography>

                <span className="value">$0.0</span>
              </div>
              <div className="item">
                <Typography variant="43" sx={{ fontWeight: 300 }}>
                  TOTAL
                </Typography>

                <Typography variant="h3">
                  ${filesObject.total ? filesObject.total.toFixed(2) : 0.0}
                </Typography>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
