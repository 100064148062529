import { useUser } from "../stores";

const useZustant = () => {
  const user = useUser((state) => state.user);

  const getUser = useUser((state) => state.getUser);

  const clearUser = useUser((state) => state.clearUser);

  const isLogged = useUser((state) => state.isLogged);

  const currentFolderPrefix = useUser((state) => state.currentFolderPrefix);

  const placeFolderPrefix = useUser((state) => state.placeFolderPrefix);

  const currentClientPage = useUser((state) => state.currentClientPage);

  const setCurrentClientPage = useUser((state) => state.setCurrentClientPage);

  const isOpenSidebar = useUser((state) => state.isOpenSidebar);

  const changeSidebarValue = useUser((state) => state.changeSidebarValue);

  const isInClientsSettings = useUser((state) => state.isInClientsSettings);

  const changeIsInClientsSettings = useUser(
    (state) => state.changeIsInClientsSettings
  );

  const transcriberHasUnit = useUser((state) => state.transcriberHasUnit);

  const changeTranscriberHasUnit = useUser(
    (state) => state.changeTranscriberHasUnit
  );

  const clientPrepaidBalance = useUser((state) => state.clientPrepaidBalance);

  const changeClientPrepaidBalance = useUser((state) => state.changeClientPrepaidBalance);
  
  const newOrderType = useUser((state) => state.newOrderType);
  
  const changeNewOrderType = useUser((state) => state.changeNewOrderType);
  return {
    user,
    getUser,
    clearUser,
    isLogged,
    currentFolderPrefix,
    placeFolderPrefix,
    setCurrentClientPage,
    currentClientPage,
    isOpenSidebar,
    changeSidebarValue,
    isInClientsSettings,
    changeIsInClientsSettings,
    transcriberHasUnit,
    changeTranscriberHasUnit,
    clientPrepaidBalance,
    changeClientPrepaidBalance,
    newOrderType,
    changeNewOrderType
  };
};

export default useZustant;
