import EditorUtils from "../../../editorUtils";
// Commenting this code because it's not part of the MVP
//import { withColumnsDefaultContent, withOutColumnsDefaultContent } from "./defaultContent";
import {
  handleBeforeUndo,
  handleClick,
  handleKeyDown,
  handleKeyUp,
  handleUndo,
} from "./eventHandlers";

const baseConfig = ({
  // Commenting this code because it's not part of the MVP
  //speakerList,
  //speakerOrder,
  content,
  user,
  //Commenting this code because it's not part of the MVP
  //defaultContent,
}) => {
  return {
    height: 500,
    extended_valid_elements: "script[language|type|src]",
    valid_elements: EditorUtils.validElements,
    content_css: "/editor.css",
    menubar: false,
    plugins: EditorUtils.plugins,
    quickbars_insert_toolbar: "",
    quickbars_selection_toolbar: "",
    spellchecker_active: true,
    save_onsavecallback: function () {},
    toolbar:
      user.rolename !== "Transcriber"
        ? "undo redo | bold italic underline strikethrough | spellchecker language spellcheckdialog | alignleft aligncenter alignright alignjustify | searchreplace | casechange | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media pageembed link anchor codesample | a11ycheck ltr rtl"
        : "undo redo | bold italic underline | spellchecker language spellcheckdialog | searchreplace | casechange",
    init_instance_callback: (editor) => {
      if (content && content.html !== "") {
        editor.setContent(content.html);
      }
      /*Commenting this code because it's not part of the MVP
      //add contenteditable to false
      const body = window.tinymce.activeEditor.dom.select("body");
      body[0].setAttribute("contenteditable", "false");
      if (content && content.html !== "") {
        //Getting speakers
        speakerList.current = content.speakerList.split(",");
        speakerOrder.current = content.speakerOrder;
        editor.setContent(content.html);
        
        if (
          user.rolename === "QA" ||
          user.rolename === "Admin" ||
          user.rolename === "PM"
        ) {
          let id = 1;
          const p = window.tinymce.activeEditor.dom.select("p");
          for (let i = 0; i < p.length; i++) {
            if (p[i].classList.contains("EditorContent")) {
              let textToArray = p[i].innerHTML;
              textToArray = textToArray.replace(/&nbsp;/g, " ");
              textToArray = textToArray.split(" ");
              textToArray = textToArray.filter((data) => data !== "");
              for (let j = 0; j < textToArray.length; j++) {
                if (
                  textToArray[j].includes("<strong>") &&
                  !textToArray[j].includes("</strong>")
                ) {
                  textToArray[j] = textToArray[j] + "</strong>";
                } else if (
                  textToArray[j].includes("</strong>") &&
                  !textToArray[j].includes("<strong>")
                ) {
                  textToArray[j] = "<strong>" + textToArray[j];
                } else if (
                  textToArray[j].includes("</em>") &&
                  !textToArray[j].includes("<em")
                ) {
                  textToArray[j] = "<em>" + textToArray[j];
                } else if (
                  textToArray[j].includes("<em>") &&
                  !textToArray[j].includes("</em>")
                ) {
                  textToArray[j] = textToArray[j] + "</em>";
                } else if (
                  textToArray[j].includes("</s>") &&
                  !textToArray[j].includes("<s>")
                ) {
                  textToArray[j] = "<s>" + textToArray[j];
                } else if (
                  textToArray[j].includes("<s>") &&
                  !textToArray[j].includes("</s>")
                ) {
                  textToArray[j] = textToArray[j] + "</s>";
                }
                textToArray[
                  j
                ] = `<span id="${id}" class="${id}"> ${textToArray[j]}</span>`;
                id = id + 1;
              }
              textToArray = textToArray.join("");
              p[i].innerHTML = textToArray;
            }
          }
        }
      } else {
        editor.setContent(defaultContent);
      }
      EditorUtils.displaySpeakers();
      */
    },
  };
};

const editorWithColumnsInitConfig = ({
  speakerList,
  speakerOrder,
  content,
  user,
  setEditorFocus,
  WordAlignment,
  AddSpeaker,
  lastSetTimeOutId,
  handleSave,
}) => {
   if (content?.html.includes("Speaker2ColumnNoDisplay")){
     content.html =content.html.replaceAll("Speaker2ColumnNoDisplay","SpeakerColumn")
   }
  
  return {
    ...baseConfig({
      // Commenting this code because it's not part of the MVP
      //speakerList,
      //speakerOrder,
      content,
      user,
      // Commenting this code because it's not part of the MVP
      // defaultContent: withColumnsDefaultContent
    }),
    setup: (editor) => {
      // Commenting this code because it's not part of the MVP
      //editor.on("click", (e) => handleClick(e, { setEditorFocus, user, content, WordAlignment, editor }));
      //editor.on("keydown", (e) => handleKeyDown(e, { editor, AddSpeaker }));
      //editor.on("keyup", (e) => handleKeyUp(e, { lastSetTimeOutId, handleSave, editor }))
      //editor.on("undo", (e) => handleUndo(e, { editor }))
    },
  };
};

const editorWithOutColumnsInitConfig = ({
  speakerList,
  speakerOrder,
  content,
  user,
  setEditorFocus,
  WordAlignment,
  AddSpeaker,
  lastSetTimeOutId,
  handleSave,
}) => {
  if (content?.html.includes("SpeakerColumn")){
    content.html =content.html.replaceAll("SpeakerColumn","Speaker2ColumnNoDisplay")
  }

  return {
    ...baseConfig({
      speakerList,
      speakerOrder,
      content,
      user,
      // Commenting this code because it's not part of the MVP
      //defaultContent: withOutColumnsDefaultContent
    }),
    setup: (editor) => {
      /* Commenting this code because it's not part of the MVP
      editor.on("click", (e) =>
        handleClick(e, { setEditorFocus, user, content, WordAlignment, editor })
      );
      editor.on("keydown", (e) => handleKeyDown(e, { editor, AddSpeaker }));
      editor.on("keyup", (e) =>
        handleKeyUp(e, { lastSetTimeOutId, handleSave, editor })
      );
      editor.on("undo", (e) => (e) => handleUndo(e, { editor }));*/
    },
  };
};

export { editorWithColumnsInitConfig, editorWithOutColumnsInitConfig };
