import axios from "axios";
import Config from "../config/index";
import { useUser } from "../stores";
const URL = window.URL || window.webkitURL;
const API = Config.api;
const base = new URL(API);

function errorResponseHandler(error) {
  if (error.response) {
    if (window.location.pathname !== "/") {
      useUser.getState().validateUser();
      if (error.response.status === 403) {
        alert(`${error.response.data}`);
      } else {
        if (error.response.status === 409) {
          alert(`Conflict accessing the resource`);
        } else {
          if (error.response.status === 404) {
            alert(`Resource not found`);
          } else {
            alert(`${error.response.data}`);
          }
        }
      }
    }
  }
  return Promise.reject(error);
}

const request = axios.create({
  baseURL: base.toLocaleString(),
  withCredentials: true,
  timeout: 30000000,
});

// apply interceptor on response
request.interceptors.response.use((response) => response, errorResponseHandler);

const construct = function (pathname) {
  let url = API;
  url += pathname;
  return url.toLocaleString();
};

const Requests = {
  getEndpoint() {
    return API;
  },
  buildURL(pathname) {
    return construct(pathname);
  },
  getUser() {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.get(construct("/user"), options);
  },
  login(user) {
    const options = {
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(construct("/login"), JSON.stringify(user), options);
  },
  logout() {
    return request.get(construct(`/logout`));
  },
  getProjects(params) {
    return request.get(construct("/projects"), { params: params });
  },
  getInactiveProjects(params) {
    return request.get(construct("/projects/inactive"), { params: params });
  },
  getClientProjects(id) {
    return request.get(construct(`/client/${id}/projects`));
  },
  getProject(id) {
    return request.get(construct(`/projects/${id}`));
  },
  getTranscript(id) {
    return request.get(construct(`/transcripts/${id}`));
  },
  changeSentStatus(id, sentStatus, reasonLate) {
    return request.put(construct(`/transcripts/${id}/changeSentStatus`), {
      sent: sentStatus,
      reason_late: reasonLate,
    });
  },
  setClientDashboardtStatus(id, dashboardStatus) {
    return request.put(
      construct(`/transcripts/${id}/changeStatusClientDashboard`),
      {
        show_in_one_board: dashboardStatus,
      }
    );
  },
  getFFUser(id) {
    return request.get(construct(`/user/${id}`));
  },
  getSpecificUser(id) {
    return request.get(construct(`/user/${id}`));
  },
  getQueue(id) {
    return request.get(construct(`/user/${id}/queue`));
  },
  requestAssignment(id) {
    return request.get(construct(`/user/${id}/requestassignment`));
  },
  showrequestAssignment(id) {
    return request.get(construct(`/user/${id}/showrequestAssignment`));
  },
  getPeopleList(params) {
    return request.get(construct(`/user/list`), { params: params });
  },
  getTranscribers() {
    return request.get(construct(`/user/transcribers`));
  },
  getTranscribersForReassign(transcriptId, unitId, includeNextDay) {
    return request.get(
      construct("/user/transcribersforreassign") +
        `?transcriptId=${transcriptId}&unitId=${unitId}&include=${includeNextDay}`
    );
  },
  getClients(params) {
    return request.get(construct(`/client/list`), { params: params });
  },
  getVocabulary(params) {
    return request.get(construct(`/client/vocabulary`), { params: params });
  },
  getClient(id) {
    return request.get(construct(`/client/${id}`));
  },
  getClientTemplates(id) {
    return request.get(construct(`/client/${id}/templates`));
  },
  getTemplate(id) {
    return request.get(construct(`/template/${id}`));
  },
  getAssignment(id, unitId) {
    return request.get(
      construct(`/transcripts/${id}/getassignment`) + `?unit=${unitId}`
    );
  },
  getQAQueue(params) {
    return request.get(construct(`/transcripts/readyforreview`), {
      params: params,
    });
  },
  getTranscriptReadyToCombine() {
    return request.get(construct(`/transcripts/readytocombine`));
  },
  getTranscriptOutForReview() {
    return request.get(construct(`/transcripts/outforreview`));
  },
  getActionItems(params) {
    return request.get(construct(`/transcripts/actionitems`), {
      params: params,
    });
  },
  getPms(active) {
    let params = "";
    if (typeof active != "undefined") {
      params += "?active=" + active;
    }
    return request.get(construct(`/user/pm`) + params);
  },
  getAvailability(id) {
    return request.get(construct(`/user/${id}/availability`));
  },
  getFileURL(id) {
    return construct(`/file/${id}`);
  },
  getTranscriptURL(id) {
    return construct(`/file/transcript/${id}`);
  },
  updateFile(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/file/${id}`), doc, options);
  },
  getWordTemplate(id) {
    return construct(`/transcript/${id}/worddoc`);
  },
  getInvoice(id) {
    return request.get(construct(`/projects/${id}/invoicepreview`));
  },
  getPayPalReport(startDate, endDate) {
    return request.get(
      construct("/assignments/paypalreport") +
        `?start=${startDate}&end=${endDate}`
    );
  },
  getClientSummary(client, startDate, endDate) {
    return request.get(
      construct("/reports/clientsummary") +
        `?client=${client}&start=${startDate}&end=${endDate}`
    );
  },
  getDailyAvailability(country, startDate, endDate) {
    return request.get(
      construct("/reports/dailyavailability") +
        `?country=${country}&start=${startDate}&end=${endDate}`
    );
  },
  getInactiveTranscribers(range) {
    return request.get(
      construct("/reports/inactivetranscribers") + `?range=${range}`
    );
  },
  getReassignedUnits(sort, direction) {
    return request.get(
      construct("/reports/reassignedunits") +
        `?sort=${sort}&direction=${direction}`
    );
  },
  getProjectManagerScore(startDate, endDate) {
    return request.get(
      construct("/reports/projectmanagerscore") +
        `?start=${startDate}&end=${endDate}`
    );
  },
  getSpecialFormats(format, startDate, endDate) {
    return request.get(
      construct("/reports/specialformats") +
        `?format=${format}&start=${startDate}&end=${endDate}`
    );
  },
  getHourSummary(startDate, endDate, ai = false) {
    return request.get(
      construct("/reports/hoursummary") +
        `?start=${startDate}&end=${endDate}&ai=${ai}`
    );
  },
  getProjectDueSummary() {
    return request.get(construct("/reports/projectduesummary"));
  },
  getUnitStateSummary() {
    return request.get(construct("/reports/unitstatesummary"));
  },
  getProofreaderSummary(startDate, endDate) {
    return request.get(
      construct("/reports/proofreadersummary") +
        `?start=${startDate}&end=${endDate}`
    );
  },
  getPMSummary(startDate, endDate) {
    return request.get(
      construct("/reports/pmsummary") + `?start=${startDate}&end=${endDate}`
    );
  },
  getTranscriberAgingSummary(startDate, endDate) {
    return request.get(
      construct("/reports/transcriberagingsummary") +
        `?start=${startDate}&end=${endDate}`
    );
  },
  getActionItemHourSummary(startDate, endDate) {
    return request.get(
      construct("/reports/actionitemhoursummary") +
        `?start=${startDate}&end=${endDate}`
    );
  },
  getUserPay(id, startDate, endDate, includeNonComplete = false) {
    return request.get(
      construct(`/user/${id}/pay`) +
        `?start=${startDate}&end=${endDate}&includNonCompleted=${includeNonComplete}`
    );
  },
  getUserLateUnits(id, startDate, endDate) {
    return request.get(
      construct(`/user/${id}/lateUnits`) + `?start=${startDate}&end=${endDate}`
    );
  },
  downloadInvoice(id) {
    return construct(`/invoice/${id}/download`);
  },
  exportPeople(name, email, role, active) {
    return (
      construct(`/invoice/exportPeople`) +
      `?name=${name}&email=${email}&role=${role}&active=${active}`
    );
  },
  exportPayPal(startDate, endDate) {
    return (
      construct(`/report/paypaldownload`) + `?start=${startDate}&end=${endDate}`
    );
  },
  exportClientSummary(client, startDate, endDate) {
    return (
      construct(`/report/clientsummary`) +
      `?client=${client}&start=${startDate}&end=${endDate}`
    );
  },
  exportHourSummary(startDate, endDate, ai = false) {
    return (
      construct(`/report/hoursummary`) +
      `?start=${startDate}&end=${endDate}&ai=${ai}`
    );
  },
  exportTranscriberPaySummary(transcriber, startDate, endDate) {
    return (
      construct(`/report/transcriberpaysummary`) +
      `?transcriber=${transcriber}&start=${startDate}&end=${endDate}`
    );
  },
  exportProjectDueSummary() {
    return construct(`/report/projectduesummary`);
  },
  exportUnitStateSummary() {
    return construct(`/report/unitstatesummary`);
  },
  exportProofreaderSummary(startDate, endDate) {
    return (
      construct(`/report/proofreadersummary`) +
      `?start=${startDate}&end=${endDate}`
    );
  },
  exportPMSummary(startDate, endDate) {
    return (
      construct(`/report/pmsummary`) + `?start=${startDate}&end=${endDate}`
    );
  },
  exportTranscriberAgingSummary(startDate, endDate) {
    return (
      construct(`/report/transcriberagingsummary`) +
      `?start=${startDate}&end=${endDate}`
    );
  },
  exportActionItemHourSummary(startDate, endDate) {
    return (
      construct(`/report/actionitemhoursummary`) +
      `?start=${startDate}&end=${endDate}`
    );
  },
  downloadObjects(paths) {
    var pathsParams = "?path=" + encodeURIComponent(paths[0]);
    for (var i = 1; i < paths.length; i++) {
      pathsParams += "&path=" + encodeURIComponent(paths[i]);
    }
    return construct(`/shared/downloadObjects`) + `${pathsParams}`;
  },
  syncProjects() {
    return request.get(construct("/crmsync/projects"));
  },
  syncAudio(id) {
    return request.get(construct(`/unit/${id}/push`));
  },
  fixAssignment(id) {
    return request.get(construct(`/unit/${id}/fix`));
  },
  /*audioFix(unitId) {
    return request.get(construct(`/unit/${unitId}/audioFix`));
  },*/
  audioFix(unitId) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(construct(`/audioFix`), { unit: unitId }, options);
  },
  focusVisionInvoices(clientId) {
    return request.get(construct(`/client/${clientId}/createSpecialInvoice`));
  },
  saveProject(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/projects/${id}`), doc, options);
  },
  saveMacrosInProject(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/projects/${id}/macros`), doc, options);
  },
  saveTranscript(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/transcripts/${id}`), doc, options);
  },
  saveUnit(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/unit/${id}`), doc, options);
  },
  saveUnitDueDate(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/unit/${id}/duedate`), doc, options);
  },
  getUnitLog(id) {
    return request.get(construct(`/unit/${id}/log`));
  },
  exportUnitLog(id) {
    return request.get(construct(`/unit/${id}/log/export`), {
      responseType: "blob",
    });
  },
  saveClient(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/client/${id}`), doc, options);
  },
  getClientUsers(id) {
    return request.get(construct(`/client/${id}/clientUsers`));
  },
  getClientUsersByProject(id) {
    return request.get(construct(`/client/${id}/clientUsersByProject`));
  },
  getProjectUsers(id) {
    return request.get(construct(`/client/${id}/projectUsers`));
  },
  getFolderUsers(id) {
    return request.get(construct(`/client/${id}/folderUsers`));
  },
  getUserFolders(id) {
    return request.get(construct(`/client/${id}/userFolders`));
  },
  saveNewClientUser(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/client/${id}/newClientUser`), doc, options);
  },
  saveNewFolderUser(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/client/${id}/newFolderUser`), doc, options);
  },
  saveNewProjectUser(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/client/${id}/newProjectUser`), doc, options);
  },
  deleteProjectUser(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(
      construct(`/client/${id}/deleteProjectUser`),
      doc,
      options
    );
  },
  deleteFolderUser(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(
      construct(`/client/${id}/deleteFolderUser`),
      doc,
      options
    );
  },
  addUserToFolder(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/client/${id}/addFolderUser`), doc, options);
  },
  deleteClientUser(doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(
      construct(`/client/${doc.clientId}/deleteClientUser`),
      doc,
      options
    );
  },
  hideTutorialUser(user_id) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/client/hidetutorial/${user_id}`), options);
  },
  createClient(data) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(construct(`/client`), data, options);
  },
  generateAPIKey(clientId) {
    return request.post(construct(`/client/${clientId}/apikey`));
  },
  uploadFileToDb(data) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(`/user/fileUploading`, data, options);
  },
  searchFiles(str, path) {
    str = encodeURIComponent(str);
    return request.get(construct(`/client/managements/search`) + `?str=${str}`);
  },
  listFiles(currentFolder) {
    currentFolder = encodeURIComponent(currentFolder);
    return request.get(
      construct(`/client/managements/list-files`) +
        `?currentFolder=${currentFolder}`
    );
  },
  registerPublicFiles(data) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/publicUpload/registerPublicFiles`),
      data,
      options
    );
  },
  getInfoFile(data) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(construct(`/client/infofile`), data, options);
  },
  generateVideoURL(awsPath) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/client/getVideoStreaming`),
      { path: awsPath },
      options
    );
  },
  getVideoSRC(URL) {
    return request.get(construct(`/videoURL/${URL}`));
  },
  generateURL(awsPath) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/client/generateURL`),
      { path: awsPath },
      options
    );
  },
  generatePublicUploadURL(awsPath) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/client/generatePublicUploadURL`),
      { path: awsPath },
      options
    );
  },
  getPublicFolderPath(url) {
    return request.get(construct(`/publicUpload/${url}`));
  },
  linkToProject(folderId, projectId) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/client/linkFolder`),
      { folderId, projectId },
      options
    );
  },
  renameObject(originalPath, newPath) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/client/renameobject`),
      { originalPath, newPath },
      options
    );
  },
  copyObject(originalPath, newPath) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/client/copyobject`),
      { originalPath, newPath },
      options
    );
  },
  sendBack(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/unit/${id}/sendback`), doc, options);
  },
  getUnit(id) {
    return request.get(construct(`/unit/${id}`));
  },
  approveTranscript(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/unit/${id}/approveunit`), doc, options);
  },
  changeStateTranscript(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(
      construct(`/transcripts/${id}/changestate`),
      doc,
      options
    );
  },
  setReview(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/assignments/${id}/review`), {}, options);
  },
  adjustPayRate(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/assignments/${id}`), doc, options);
  },
  changeStateUnit(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/unit/${id}/changestate`), doc, options);
  },
  changeStateUnitV2(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/unit/${id}/changestatev2`), doc, options);
  },

  bulkTranscripts(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(
      construct(`/projects/${id}/bulktranscripts`),
      doc,
      options
    );
  },
  createTranscript(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(
      construct(`/projects/${id}/createtranscript`),
      doc,
      options
    );
  },

  createTranscriptBeforeUpload(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/projects/${id}/createTranscriptBeforeUpload`),
      doc,
      options
    );
  },

  getTranscriptIdBeforeUpload(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.get(
      construct(`/projects/${id}/getTranscriptIdBeforeUpload`),
      doc,
      options
    );
  },
  split(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/transcripts/${id}/split`), doc, options);
  },
  reSplit(id, data) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/unit/${id}/resplit`), data, options);
  },
  reject(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/unit/${id}/reject`), doc, options);
  },
  assign(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/unit/${id}/assign`), doc, options);
  },
  reassign(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/unit/${id}/reassign`), doc, options);
  },
  getSummaryReportUnits(params) {
    return request.get(construct("/unit/summary-report"), { params: params });
  },
  getSummaryReportProjects(params) {
    return request.get(construct("/projects/summary-report"), {
      params: params,
    });
  },
  requestInvoice(projectId) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/projects/${projectId}/requestInvoice`),
      {},
      options
    );
  },
  sendToQuickBooks(projectId) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/projects/${projectId}/sendToQuickBooks`),
      {},
      options
    );
  },
  egnyteUpload(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/projects/${id}/upload`), doc, options);
  },
  createTemplate(doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(construct(`/template`), doc, options);
  },
  saveTemplate(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/template/${id}`), doc, options);
  },
  uploadMacro(doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "multipart/form-data",
      },
    };
    return request.post(construct(`/template/macrofile`), doc, options);
  },
  createAvailability(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(construct(`/user/${id}/availability`), doc, options);
  },
  saveAvailability(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/user/${id}/availability`), doc, options);
  },
  createUser(doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(construct(`/user/create`), doc, options);
  },
  saveUser(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/user/${id}`), doc, options);
  },
  updateRate(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/user/${id}/rate`), doc, options);
  },
  updateDefaultTab(id, value) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(
      construct(`/user/${id}/defaultTab`),
      { defaultTab: value },
      options
    );
  },
  updatePassword(id, doc) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/user/${id}/password`), doc, options);
  },
  deactivateAccount(id) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(construct(`/user/deactivate/`), { id }, options);
  },
  ResetPassword(email, newUi) {
    const options = {
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(
      construct(`/user/reset_password`),
      { email, newUi },
      options
    );
  },
  changePassword(doc) {
    return request.put(construct(`/user/change_password`), doc);
  },
  //Uploads
  uploadAsset(data, config) {
    const optionsDefault = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    const options = Object.assign({}, optionsDefault, config);
    return request.post(construct("/uploads/project"), data, options);
  },
  uploadAttachments(data, config) {
    const optionsDefault = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    const options = Object.assign({}, optionsDefault, config);
    return request.post(construct("/uploads/attachments"), data, options);
  },
  uploadTemplate(data, config) {
    const optionsDefault = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    const options = Object.assign({}, optionsDefault, config);
    return request.post(construct("/uploads/wordtemplate"), data, options);
  },
  uploadLogo(data, config) {
    const optionsDefault = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    const options = Object.assign({}, optionsDefault, config);
    return request.post(construct("/uploads/logo"), data, options);
  },
  deleteClientLogo(_id) {
    return request.put(construct(`/client/${_id}/deleteclientlogo`));
  },
  deleteFinalTranscript(_id) {
    return request.put(construct(`/transcripts/${_id}/deletefinaltranscript`));
  },
  sendTranscript(id, emailBody) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    let data = { emailBody: emailBody };
    return request.put(
      construct(`/transcripts/${id}/sendTranscriptToClient`),
      data,
      options
    );
  },
  uploadTranscript(data, config) {
    const optionsDefault = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    const options = Object.assign({}, optionsDefault, config);
    return request.post(construct("/uploads/transcript"), data, options);
  },
  saveLink(data) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };

    return request.post(construct(`/client/url`), data, options);
  },
  deleteLink(data) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(construct(`/client/url/delete`), data, options);
  },
  moveLink(data) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(construct(`/client/url/move`), data, options);
  },
  copyLink(data) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(construct(`/client/url/copy`), data, options);
  },
  securityCheck(userId) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.get(construct(`/user/${userId}/securityCheck`), options);
  },
  securityCheckFlag(userId) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.get(construct(`/user/${userId}/AntivirusFlag`), options);
  },
  checkAPI() {
    return request.get(construct(`/`));
  },
  importProjectFromZoho(_id) {
    var urlencoded = new URLSearchParams();
    urlencoded.append("_id", _id);
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    return request.post(construct(`/webhook`), urlencoded, options);
  },
  sendEmailToPm(idUnit, dataToSend) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/unit/${idUnit}/sendEmailToPm`),
      dataToSend,
      options
    );
  },
  resetLastCompletedAsigment(idTranscriber) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(
      construct(`/user/resetLastCompletedAssigment`),
      { idTranscriber },
      options
    );
  },
  getAvailableUnits(idUser, page, pagesize) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };

    return request.get(
      construct(`/unit/availableUnits/${idUser}/${page}/${pagesize}`, options)
    );
  },
  startAeneasProcessManually(unit_id) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };

    return request.put(
      construct(`/unit/${unit_id}/startAeneasProcessManually`, options)
    );
  },
  getUnitScribeKeys(unitId, userId) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };

    return request.get(
      construct(`/unitScribeKeys/${unitId}/${userId}`),
      options
    );
  },

  createUnitScribeKeys(unitId, userId, unitScribeKeys) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };

    return request.post(
      construct(`/unitScribeKeys`),
      { unitId, userId, unitScribeKeys },
      options
    );
  },

  getUserUnit(idUser) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };

    return request.get(construct(`/unit/user/${idUser}/currentunit`, options));
  },
  getRejectionsReasons(unit_id) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.get(
      construct(`/assignments/rejectedAssignments`) + `?unit_id=${unit_id}`,
      options
    );
  },
  inviteTeamMembers(team) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/client/inviteTeamMembers`),
      { team },
      options
    );
  },
  getTeamMemberByToken(token) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.get(
      construct(`/client/getTeamMemberByToken/${token}`),
      options
    );
  },
  acceptTeamMemberInvite(team) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/client/acceptTeamMemberInvitation`),
      { team },
      options
    );
  },
  getPendingTeamMembersInvitation() {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.get(
      construct(`/client/getPendingTeamMembersInvitation`),
      options
    );
  },
  getActiveTeamMember() {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.get(
      construct(`/client/getActiveTeamMember`),
      options
    );
  },
  getForcedAlignmentJSON(id) {
    return construct(`/transcript/${id}/forcedalignmentjson`);
  },
  revokeTeamMember(teamMemberId) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(
      construct(`/client/revokeTeamMember/${teamMemberId}/revoke`),
      options
    );
  },
  getPrepaidBalance(clientId) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.get(
      construct(`/client/prepaidBalance/${clientId}`,{clientId}),
      options
    );
  },
  changePrepaidBalance(clientId,newBalance) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.put(
      construct(`/client/prepaidBalance/${clientId}/changePrepaidBalance`),
      { newBalance },
      options
    );
  },
  squarePayment(paymentDetails) {
    const options = {
      headers: {
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    };
    return request.post(
      construct(`/client/squarePayment`),
      { paymentDetails },
      options
    );
  },
};

export default Requests;
