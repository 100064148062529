import { useEffect, useState } from "react";
import useZustant from "../hooks/useZustant";
import { ThemeProvider } from "@emotion/react";
import theme from "../theme";
import {
  Container,
  Divider,
  Button,
  Typography,
  Box,
  Toolbar,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  FormControl,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
} from "@mui/material";
import FormUser from "../components/FormUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBan,
  faReceipt,
  faPen,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Utils from "../utils";
import SearchBar from "../components/SearchBar";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import CustomAlertDialogSlide from "../components/global/CustomAlertDialogSlide";
import useShow from "../hooks/useShow";
import Requests from "../logic/Requests";
import useFetch from "../hooks/useFetch";
const headCells = [
  { id: "nameHead", label: "Name" },
  { id: "emailHead", label: "Email" },
  { id: "joinedHead", label: "Joined On" },
  { id: "roleHead", label: "Role" },
  { id: "actionsHead", label: "Actions" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InviteTeammates() {
  const { setCurrentClientPage, changeIsInClientsSettings, user } = useZustant();
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();
  const [pendingInvites, setPendingInvites] = useState([]);
  const { data: dataPendingInvites, isLoading: isLoadingPendingInvites , setData: setDataPendingInvites} =
    useFetch(Requests.getPendingTeamMembersInvitation);
    const [filteredDataPendingInvites,setFilteredDataPendingInvites]=useState([]);

    // get active team member
    const { data: dataActiveTeamMember, isLoading: isLoadingActiveTeamMember , setData: setDataActiveTeamMember  } = useFetch(Requests.getActiveTeamMember);
    const [filteredDataActiveTeamMember,setFilteredDataActiveTeamMember]=useState([]);

    useEffect(()=>{
      setFilteredDataActiveTeamMember(dataActiveTeamMember)
    },[isLoadingActiveTeamMember,dataActiveTeamMember])
  
    // sort data
    const revokedSorter = (data)=>{
      return data?.sort(function(a, b) {
        if (a.revoked === b.revoked) {
          return 0; 
        } else if (a.revoked) {
          return 1; 
        } else {
          return -1; 
        }
      })
        }
        
    useEffect(()=>{
      setFilteredDataActiveTeamMember(revokedSorter(dataActiveTeamMember))
    },[isLoadingActiveTeamMember,dataActiveTeamMember])
    useEffect(()=>{
      setFilteredDataPendingInvites(revokedSorter(dataPendingInvites))
    },[isLoadingPendingInvites,dataPendingInvites])

    useEffect(()=>{
      setFilteredDataActiveTeamMember(dataActiveTeamMember)
    },[isLoadingActiveTeamMember,dataActiveTeamMember])
  
  useEffect(() => {
    setFilteredDataPendingInvites(dataPendingInvites)
    document.title = "Invite Teammates - Focus Forward";
    setCurrentClientPage("Invite Teammates");
    changeIsInClientsSettings(false);
    if (!isLoadingPendingInvites && dataPendingInvites) {
      setPendingInvites([...dataPendingInvites]);
    }
  }, [dataPendingInvites, isLoadingPendingInvites]);

  const [invitationsNumber, setInvitationsNumber] = useState([
    { id: 0, first_name: "", last_name: "", email: "" },
  ]);

  const removeItem = (id) => {
    const newItems = invitationsNumber.filter((item) => item.id !== id);
    setInvitationsNumber(newItems);
  };

  const handleChange = (id, field, value) => {
    setInvitationsNumber((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return { ...item, [field]: value };
        }
        return item;
      });
    });
  };

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setRevokedUsersCheckMarck(true);
    setTabValue(newValue);
  };

  const inviteTeamMembers = async () => {
    if (validInvitations()) {
      try {
        await Requests.inviteTeamMembers(invitationsNumber);
        setInvitationsNumber([
          { id: 0, first_name: "", last_name: "", email: "" },
        ]);
        setCurrentAlertState({
          type: "success",
          message: "Invitations sent",
        });
        openAlert();
      } catch (error) {
        setCurrentAlertState({
          type: "error",
          message: error.message,
        });
        openAlert();
      }
    }
  };

  useEffect(() => {}, [invitationsNumber]);

  const validInvitations = () => {
    const invalidInvitations = invitationsNumber.filter((invitation) => {
      return (
        !invitation.first_name ||
        !invitation.email ||
        !Utils.validateEmail(invitation.email)
      );
    });
    if (invalidInvitations.length > 0) {
      setCurrentAlertState({
        type: "error",
        message:
          "Make sure that the name and email are not empty and that the email is in a valid format.",
      });
      openAlert();
      return false;
    }
    return true;
  };

    // transform date
    function formattedDate(dateStr){
      const dateObj = new Date(dateStr);
      const formattedDate = dateObj.toLocaleDateString('en-US'); // Adjust the locale if needed
      return formattedDate;
    }
  // handle search of active users
  const [searchActiveTeamMember,setSearchActiveTeamMember]=useState("");
  const [searchPendingTeamMember,setSearchPendingTeamMember]=useState("");
  const [openDialog,setOpenDialog]=useState(false);
  const [dialogTitle,setDialogTitle]=useState("Are you sure you want to proceed with this action?");
  const [dialogMessage,setDialogMessage]=useState("Please note that this change will be permanent and cannot be reversed afterward.");
  const [selectedTeamMemberId,setSelectedTeamMemberId]=useState("");
  const [revokedUsersCheckMarck,setRevokedUsersCheckMarck]=useState(true);

  useEffect(()=>{
    let filterResult=dataActiveTeamMember;
    if(!revokedUsersCheckMarck){
      filterResult= filterResult?.filter(ActiveTeamMember=>!!ActiveTeamMember.revoked==revokedUsersCheckMarck)
    }
     filterResult =filterResult?.filter(ActiveTeamMember=> !!ActiveTeamMember.first_name.toLowerCase().includes(searchActiveTeamMember.toLowerCase()) ||  ActiveTeamMember.email.toLowerCase().includes(searchActiveTeamMember.toLowerCase()));
        
     setFilteredDataActiveTeamMember(revokedSorter(filterResult));
  },[searchActiveTeamMember,revokedUsersCheckMarck])

  useEffect(()=>{
    let filterResult=dataPendingInvites;
    if(!revokedUsersCheckMarck){
      filterResult= filterResult?.filter(pendingTeamMember=>!!pendingTeamMember.revoked==revokedUsersCheckMarck)
    }
    filterResult =filterResult?.filter(pendingTeamMember=> !!pendingTeamMember.first_name.toLowerCase().includes(searchPendingTeamMember.toLowerCase()) ||  pendingTeamMember.email.toLowerCase().includes(searchPendingTeamMember.toLowerCase()) );
    setFilteredDataPendingInvites(revokedSorter(filterResult));
  },[searchPendingTeamMember,revokedUsersCheckMarck])
  
  // handle revoke teammember
  const revokeTeamMember = async (teamMemberId,setFilteredDataPendingInvites, setFilteredDataActiveTeamMember,setDataPendingInvites,setDataActiveTeamMember) => {
    try {
      const res = await Requests.revokeTeamMember(teamMemberId);
      setCurrentAlertState({
        type: "success",
        message: "Revoked Teammate",
      });
      setFilteredDataPendingInvites(res.data.pendingTeamMembers);
      setFilteredDataActiveTeamMember(res.data.activeTeamMembers);
      setDataPendingInvites(res.data.pendingTeamMembers);
      setDataActiveTeamMember(res.data.activeTeamMembers);
      openAlert();
    
    } catch (error) {
      setCurrentAlertState({
        type: "error",
        message: error.message,
      });
      openAlert();
    }
  };
  function handleRevokeClick(teamMemberId,revoked){
    if(revoked){
      setCurrentAlertState({
        type: "error",
        message: "Cannot change the revoked Team Member",
      });
      openAlert();
    }else{
      let teamMember=filteredDataActiveTeamMember.filter(teamMember=>teamMember._id===teamMemberId);
      if(teamMember.length==0){
        teamMember=filteredDataPendingInvites.filter(teamMember=>teamMember._id===teamMemberId);
      }
  
      setSelectedTeamMemberId(teamMemberId)
      setDialogMessage(`Are you sure you want to proceed to revoke ${teamMember[0]?.first_name} ${!!teamMember[0].last_name?teamMember[0].last_name:teamMember[0].last_name}? Please note that this change will be permanent and cannot be reversed afterward.`)
      setOpenDialog(true);
    }
  }
  
  return (
    <ThemeProvider theme={theme}>
      <Container style={{ width: "100%", paddingTop: "50px" }}>
        <CustomSnackBarAlert
          type={currentAlertState.type}
          show={showAlert}
          close={closeAlert}
        >
          {currentAlertState.message}
        </CustomSnackBarAlert>
        <Typography variant="h1">Invite Teammates</Typography>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="informationDiv">
            <FontAwesomeIcon
              icon={faUsers}
              style={{ color: theme.palette.fourth.main }}
              className="informationIcon"
            />
            <Typography variant="p">Divide and conquer</Typography>
            <Divider className="invisibleDivider" />
            <Typography variant="p" className="informationBody">
              Allow multiple team members to order through one billing account
            </Typography>
          </div>

          <div className="informationDiv">
            <FontAwesomeIcon
              icon={faPen}
              style={{ color: theme.palette.fourth.main }}
              className="informationIcon"
            />
            <Typography variant="p">Create a content hub</Typography>
            <Divider className="invisibleDivider" />

            <Typography variant="p" className="informationBody">
              Access and edit files across your entire team
            </Typography>
          </div>

          <div className="informationDiv">
            {/* <ReceiptIcon
              sx={{ color: theme.palette.fourth.main }}
              className="informationIcon"
            /> */}

            <FontAwesomeIcon
              icon={faReceipt}
              style={{ color: theme.palette.fourth.main }}
              className="informationIcon"
            />
            <Typography variant="p">Centralize billing control</Typography>
            <Divider className="invisibleDivider" />

            <Typography variant="p" className="informationBody">
              Review transaction history, manage payments and invoices, and
              update billing settings for your entire team
            </Typography>
          </div>
        </div>
        {user.client_user_role === "admin" ? (
          <div className="formsContainer">
            <Typography variant="h2">Invite a User</Typography>

            <ul style={{ padding: 0 }}>
              {invitationsNumber.map((invitation) => (
                <li
                  key={invitation.id}
                  index={invitation.id}
                  style={{ listStyle: "none", marginBottom: "15px" }}
                >
                  <FormUser
                    index={invitation.id}
                    data={invitation}
                    handleChange={handleChange}
                    removeItem={removeItem}
                  />
                </li>
              ))}
            </ul>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Button
                onClick={() => {
                  if (validInvitations()) {
                    setInvitationsNumber([
                      ...invitationsNumber,
                      { id: invitationsNumber.length + 1 },
                    ]);
                  }
                }}
                startIcon={
                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: "14px" }} />
                }
                sx={{ textTransform: "none" }}
              >
                Invite Another User
              </Button>
              <Divider sx={{ my: 1, borderColor: "transparent" }} />

              <Button variant="contained" onClick={inviteTeamMembers}>
                {" "}
                Send Invite(s)
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
        <Divider sx={{ my: 2 }} />

        <Box sx={{ borderBottom: 1 }}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="Active Users" {...a11yProps(0)} />
            {isLoadingPendingInvites ? (
              <Tab label="Pending Invites (LOADING...)" {...a11yProps(1)} />
            ) : (
              <Tab
                label={`Pending Invites (${dataPendingInvites?.length})`}
                {...a11yProps(1)}
              />
            )}
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <div className="teamMates">
          { isLoadingActiveTeamMember ?
            <div>Loading Active Teammates...</div> :
            <Box sx={{ width: "100%" }}>

              <Toolbar className="usersToolbar">
                <Typography variant="h5" className="toolbarLabel">
                  {filteredDataActiveTeamMember?.length} teammate(s)
                </Typography>
                <div style={{ display: "flex" }}>
                  <FormControlLabel
                    control={<Checkbox  defaultChecked onChange={(event)=>setRevokedUsersCheckMarck(event.target.checked)} />}
                    label="Revoked users"
                    variant="h5"
                  />
                  <SearchBar placeholder="Search name or email" onChange={event=>setSearchActiveTeamMember(event.target.value)}/>
                </div>
              </Toolbar>

              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={"left"}
                            sx={{
                              minWidth: "75px !important",
                            }}
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        filteredDataActiveTeamMember?.length!==0?
                          <>
                            {filteredDataActiveTeamMember?.map((teamMemberData,i)=>{
                              return (
                                <TableRow
                                  key={i}
                                  id={teamMemberData._id}
                                  className={
                                    teamMemberData?.revoked ?
                                    "revokedTeamMember"
                                    :
                                    ""
                                  }
                                >
                                  <TableCell>
                                    {teamMemberData.first_name}
                                  </TableCell>
                                  <TableCell>{teamMemberData.email}</TableCell>
                                  <TableCell>
                                    {formattedDate(teamMemberData.createdAt)}
                                  </TableCell>
                                  <TableCell>
                                    <FormControl>
                                      <Select
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                        value={20}
                                        sx={{ maxHeight: "40px" }}
                                      >
                                        <MenuItem value={10}>Manager</MenuItem>
                                        <MenuItem value={20}>User</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title="Revoke">
                                      <IconButton
                                        aria-label="remove"
                                        onClick={() =>{console.log("to revoke");
                                          handleRevokeClick(
                                            teamMemberData._id,
                                            teamMemberData.revoked
                                          )}
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faBan}
                                          style={
                                            teamMemberData.revoked
                                              ? {
                                                  color: "#9BA4B5",
                                                  cursor: "not-allowed",
                                                }
                                              : {
                                                  color: theme.palette.red.main,
                                                }
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </>:<>{
                            searchActiveTeamMember?.length !==0?
                            <div>There are no matches with your search</div>
                            :
                            <div>There are no Active Teammates yet</div>  
                            }</>    
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>}
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="teamMates">
            {isLoadingPendingInvites ? (
              <div>Loading Pending Teammates...</div>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Toolbar className="usersToolbar">
                  <Typography variant="h5" className="toolbarLabel">
                    {filteredDataPendingInvites?.length} teammate(s)
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <FormControlLabel
                      control={<Checkbox defaultChecked onChange={(event)=>setRevokedUsersCheckMarck(event.target.checked)} />}
                      label="Revoked users"
                      variant="h5"
                    />
                    <SearchBar
                      placeholder="Search name or email"
                      onChange={(event) =>
                        setSearchPendingTeamMember(event.target.value)
                      }
                    />
                  </div>
                </Toolbar>

                <Paper sx={{ width: "100%", mb: 2 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              align={"left"}
                              sx={{
                                minWidth: "75px !important",
                              }}
                            >
                              {headCell.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          {isLoadingPendingInvites ? (
                            <Typography variant="h2" sx={{ my: 4 }}>
                              Loading Pending Teammates...
                            </Typography>
                          ) : (
                            <>
                              {filteredDataPendingInvites?.length !== 0 ? (
                                <>
                                  {filteredDataPendingInvites?.map(
                                    (pendingTeamMemberData, i) => {
                                      return (
                                        <TableRow key={i} id={pendingTeamMemberData._id} className={pendingTeamMemberData?.revoked ? "revokedTeamMember" : ""}>
                                          <TableCell>
                                            {pendingTeamMemberData.first_name}
                                          </TableCell>
                                          <TableCell>
                                            {pendingTeamMemberData.email}
                                          </TableCell>
                                          <TableCell>
                                            {formattedDate(
                                              pendingTeamMemberData.createdAt
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            <FormControl>
                                              <Select
                                                inputProps={{
                                                  "aria-label": "Without label",
                                                }}
                                                value={20}
                                                sx={{ maxHeight: "40px" }}
                                              >
                                                <MenuItem value={10}>
                                                  Manager
                                                </MenuItem>
                                                <MenuItem value={20}>
                                                  User
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell>
                                            <Tooltip title="Revoke">
                                              <IconButton aria-label="remove" onClick={()=>handleRevokeClick(pendingTeamMemberData._id,pendingTeamMemberData.revoked)}>
                                                <FontAwesomeIcon
                                                  icon={faBan}
                                                  style={pendingTeamMemberData.revoked?{
                                                    color:
                                                     "#9BA4B5",
                                                    cursor: "not-allowed",
                                                  }:{color:
                                                    theme.palette.red.main}}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <>
                                  {searchPendingTeamMember?.length !== 0 ? (
                                    <Typography variant="h2" sx={{ my: 4 }}>
                                      There are no matches with your search
                                    </Typography>
                                  ) : (
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <Typography variant="h2" sx={{ my: 4 }}>
                                        Invite Sent
                                      </Typography>
                                      <Typography
                                        variant="h4"
                                        className="body"
                                        sx={{
                                          fontWeight: "300 !important",
                                          mb: 4,
                                        }}
                                      >
                                        Pending invitations will be shown here
                                        when available.
                                      </Typography>
                                    </Box>
                                  )}
                                </>
                              )}
                            </>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            )}
          </div>
        </TabPanel>
      </Container>
      <CustomAlertDialogSlide open={openDialog} setOpen={setOpenDialog} title={dialogTitle} message={dialogMessage} functionOnAgree={()=>revokeTeamMember(selectedTeamMemberId,setFilteredDataPendingInvites,setFilteredDataActiveTeamMember,setDataPendingInvites,setDataActiveTeamMember)}/>
    </ThemeProvider>
  );
}
