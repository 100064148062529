import React, { useEffect, useState } from "react";

function RenderCost(props) {
  const { durationFile, handleCost, isAudioOrVideo, basicRate } = props;

  const [cost, setCost] = useState();

  useEffect(() => {
    const durationInMinutes = durationFile / 60;
    const fixedCost = basicRate;
    const currentCost = durationInMinutes * basicRate;
    const formattedCost = currentCost.toFixed(2);

    if (!isAudioOrVideo) {
      handleCost(0.0);
      setCost(0.0);
    } else if (durationFile < 60) {
      handleCost(fixedCost);
      setCost(fixedCost);
    } else {
      handleCost(formattedCost);
      setCost(formattedCost);
    }
  }, [durationFile]);

  return <span>$ {cost}</span>;
}

export default React.memo(RenderCost);
