import { React, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import { Card, Box, FormControl, InputBase, InputLabel } from "@mui/material";
import SquarePayment from "../components/SquarePayment";
import Requests from "../logic/Requests";
import useZustant from "../hooks/useZustant";
import { useNavigate } from "react-router-dom";


export default function Checkout() {
  const { user, clientPrepaidBalance, changeClientPrepaidBalance} = useZustant();
  useEffect(() => {
    document.title = "Checkout - Focus Forward Tops";
  }, []);
  const navigate = useNavigate();
  const inputStyle = {
    marginTop: theme.spacing(3),
    position: "relative",
    borderRadius: "4px",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    borderBottom: "none",
    border: "1px solid #D3D3D3",
    fontSize: 18,
    padding: "5px 6px",
    height: "40px",
  
    "&::before": {
      display: "none",
    },
  };

  const [amount, setAmount]=useState("");
  //useEffect(()=>console.log(amount),[amount]);
  const handelSuccessPayment= async (client, amountPaid)=>{
    const newAmount = parseFloat(amountPaid) + parseFloat(clientPrepaidBalance);
    console.log(newAmount)
    const clientUpdated = await Requests.changePrepaidBalance(client, newAmount);
    console.log(clientUpdated.data.updatedClient.prepaidBalance);
    changeClientPrepaidBalance(clientUpdated.data.updatedClient.prepaidBalance.toFixed(2));
    navigate("/transactionhistory");
  }


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: 3, mx: { xs: 2, md: 10 } }}>
        <Card component="main">
          <h1>PREPAY CHECKOUT</h1>
        </Card>
        <Card>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
              padding: "24px",
            }}
          >
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink>AMOUNT</InputLabel>
              <InputBase
                sx={inputStyle}
                id="amount"
                name="amount"
                onChange={(e) => setAmount(e.target.value)}
              />
            </FormControl>
          </Box>
        </Card>
        <SquarePayment
          style={{ padding: "24px" }}
          amount={amount}
          currency={"USD"}
          afterPayment={()=>handelSuccessPayment(user.client,amount)}
        />
      </Box>
    </ThemeProvider>
  );
}
